import { Pipe, PipeTransform } from '@angular/core';

const tz = '2000-01-02T12:34:56Z';
const tzLen = tz.length;
const tPos = tz.indexOf('T');
const zPos = tz.indexOf('Z');

@Pipe({
  name: 'timeTz',
})
export class TimeTzPipe implements PipeTransform {
  /**
   * 2000-01-02T12:34:56Z | timeTz  => 2000-01-02 12:34
   *
   * @param value unknown or data/time
   * @return unchanged or 2000-01-02T12:34:56Z => 2000-01-02 12:34
   */
  transform(value: unknown): unknown {
    if (
      typeof value === 'string' &&
      value.length === tzLen &&
      value.indexOf('T') == tPos &&
      value.indexOf('Z') === zPos
    ) {
      value = value.substring(0, tPos) + ' ' + value.substring(tPos + 1, zPos - 3);
    }

    return value;
  }
}
