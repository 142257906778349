<div [formGroup]="form" class="flex flex-col space-y-2">
  <div>{{ 'hlds.settings.algorithms.autoReflex-heading' | translate }}</div>
  <mat-checkbox [formControl]="controls.autoReflex"
    >{{ 'hlds.settings.algorithms.autoReflex' | translate }}
  </mat-checkbox>
  <div>{{ 'hlds.settings.algorithms.interpretation-heading' | translate }}</div>
  <mat-checkbox [formControl]="controls.interpretation"
    >{{ 'hlds.settings.algorithms.interpretation' | translate }}
  </mat-checkbox>
  <mat-accordion class="mt-8">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.disposition.config-title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="disposition_"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.interp.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="interp_"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.test-custom.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="test_"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.settings.algorithms.color' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="color_"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.settings.algorithms.label.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="label_"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #color_>
  <div class="grid grid-cols-4 gap-8 max-w-6xl items-baseline justify-items-center">
    <div></div>
    <div>{{ 'hlds.settings.algorithms.fill-color' | translate }}</div>
    <div>{{ 'hlds.settings.algorithms.outline-color' | translate }}</div>
    <div></div>

    <div>{{ 'hlds.node-edit.type.cond.radio' | translate }}</div>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.cond.fill"
      (onChangeComplete)="changeComplete($event, 'cond', 'fill')"
    ></color-block>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.cond.outline"
      (onChangeComplete)="changeComplete($event, 'cond', 'outline')"
    ></color-block>
    <button mat-button (click)="reset('cond')">
      {{ 'hlds.settings.algorithms.reset' | translate }}
    </button>

    <div>{{ 'hlds.node-edit.type.comment.radio' | translate }}</div>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.comment.fill"
      (onChangeComplete)="changeComplete($event, 'comment', 'fill')"
    ></color-block>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.comment.outline"
      (onChangeComplete)="changeComplete($event, 'comment', 'outline')"
    ></color-block>
    <button mat-button (click)="reset('comment')">
      {{ 'hlds.settings.algorithms.reset' | translate }}
    </button>

    <div>{{ 'hlds.node-edit.type.lab_panel.radio' | translate }}</div>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.lab_panel.fill"
      (onChangeComplete)="changeComplete($event, 'lab_panel', 'fill')"
    ></color-block>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.lab_panel.outline"
      (onChangeComplete)="changeComplete($event, 'lab_panel', 'outline')"
    ></color-block>
    <button mat-button (click)="reset('lab_panel')">
      {{ 'hlds.settings.algorithms.reset' | translate }}
    </button>

    <div>{{ 'hlds.node-edit.type.stop.radio' | translate }}</div>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.stop.fill"
      (onChangeComplete)="changeComplete($event, 'stop', 'fill')"
    ></color-block>
    <color-block
      [colors]="blockColors"
      [color]="data.algorithms.shapes.stop.outline"
      (onChangeComplete)="changeComplete($event, 'stop', 'outline')"
    ></color-block>
    <button mat-button (click)="reset('stop')">
      {{ 'hlds.settings.algorithms.reset' | translate }}
    </button>
  </div>
</ng-template>
<ng-template #disposition_>
  <hlds-generic-table
    [rows]="data.algorithms.dispositions"
    [items]="disposition.items"
    [options]="disposition.options"
    (run)="actionDisposition($event)"
  >
  </hlds-generic-table>
</ng-template>
<ng-template #test_>
  <hlds-generic-table
    [rows]="data.algorithms.testCustoms"
    [items]="custom.items"
    [options]="custom.options"
    (run)="addCustom($event)"
  >
  </hlds-generic-table>
</ng-template>
<ng-template #interp_>
  <hlds-generic-table
    [rows]="data.algorithms.interps"
    [items]="interp.items"
    [options]="interp.options"
    (run)="actionInterp($event)"
  >
  </hlds-generic-table>
</ng-template>
<ng-template #label_>
  <div class="flex flex-col">
    <mat-form-field class="w-[15rem]">
      <mat-label>{{ 'hlds.settings.algorithms.label.true' | translate }}</mat-label>
      <input [formControl]="controls.trueLabel" matInput />
    </mat-form-field>
    <mat-form-field class="w-[15rem]">
      <mat-label>{{ 'hlds.settings.algorithms.label.false' | translate }}</mat-label>
      <input [formControl]="controls.falseLabel" matInput />
    </mat-form-field>
    <div>
      <button mat-button (click)="resetLabels()">
        {{ 'hlds.settings.algorithms.label.reset' | translate }}
      </button>
    </div>
  </div>
</ng-template>
