import { IToken, TokenType } from '@chevrotain/types';
import { NodeCond } from '../models';
import { condParse } from './cond-api';
import { condRec } from './cond-tokens';

interface CState {
  andOr?: TokenType;
  children: CState[];
  parent?: CState;
  shortCircuit: boolean;
  tf: boolean;
}

export class CondEval {
  private readonly emptyState: (parent?: CState) => CState = (parent?: CState) => ({
    children: [],
    shortCircuit: false,
    parent,
    tf: false,
  });
  private readonly missingTest: boolean; // true if at least one test missing
  private readonly state: CState = this.emptyState();
  private readonly varNames: string[];
  private readonly testValues: string[];
  private readonly tokens: IToken[];

  constructor(node: NodeCond) {
    const parse = condParse(node.cond, { strict: false });

    this.tokens = parse.tokens;
    this.varNames = node.varNames.map((id) => id.toLowerCase());
    this.testValues = node.testValues || [];

    if (!parse.valid) {
      throw new Error(`Invalid condition: ${node.cond}`);
    }

    this.missingTest = !this.testValues.every((v) => !!v);
  }

  /**
   * Run node.varNames and node.cond against node.testValues. It is assumed condition is value
   *
   * @return null = testValues are incomplete, true/false = #expression evaluation
   */
  run(): boolean | null {
    const { missingTest, state } = this;
    let result = null;

    if (!missingTest) {
      this.#run(state);
      result = state.tf;

      for (const child of state.children) {
        result = child.tf;

        if (this.#shortCircuit(result, child.andOr?.name)) {
          break;
        }
      }
    }

    return result;
  }

  #expression(id: string): boolean {
    const { testValues, varNames } = this;
    const { eq, eqeq, ge, gt, le, lt, ne } = condRec;
    const tokens = this.#pop(2);
    const compare = tokens[0];
    const value = +tokens[1].image;
    const testValue = +testValues[varNames.indexOf(id.toLowerCase())];
    let result: boolean;

    switch (compare.tokenType.name) {
      case eqeq.name:
      case eq.name:
        result = testValue === value;
        break;
      case gt.name:
        result = testValue > value;
        break;
      case ge.name:
        result = testValue >= value;
        break;
      case le.name:
        result = testValue <= value;
        break;
      case lt.name:
        result = testValue < value;
        break;
      case ne.name:
        result = testValue !== value;
        break;
      default:
        throw new Error(`expression(), unexpected operator: ${compare.tokenType.name}`);
    }

    return result;
  }

  #pop(count = 1): IToken[] {
    return this.tokens.splice(0, count);
  }

  #run(state: CState) {
    const { tokens } = this;
    const { identifier, parenLeft, logicAnd, logicOr, parenRight } = condRec;

    for (; tokens.length; ) {
      const token: IToken = this.#pop()[0];
      const {
        image,
        tokenType: { name },
      } = token;

      switch (name) {
        case identifier.name:
          if (state.shortCircuit) {
            this.#pop(2);
          } else {
            state.tf = this.#expression(image);
          }
          break;
        case logicAnd.name:
        case logicOr.name:
          if (!state.shortCircuit) {
            state.andOr = name === logicAnd.name ? logicAnd : logicOr;
            state.shortCircuit = this.#shortCircuit(state.tf, name);
          }
          break;
        case parenLeft.name:
          const newState = this.emptyState(state);

          if (state.parent) {
            state.parent.children.push(newState);
          } else {
            this.state.children.push(newState);
          }
          this.#run(newState);
          return;
        case parenRight.name:
          if (tokens.length) {
            // If there is a token following the ")", it MUST be && or ||
            state.andOr = this.#pop()[0].tokenType.name === logicAnd.name ? logicAnd : logicOr;
          }
          if (state.parent) {
            state = state.parent;
          }
          break;
        default:
          throw new Error(`run(), unexpected operator: ${name}`);
      }
    }
  }

  #shortCircuit(tf: boolean, name: string | undefined): boolean {
    const { logicAnd, logicOr } = condRec;

    return tf ? name === logicOr.name : name === logicAnd.name;
  }
}
