import { Subject } from 'rxjs';
import { BaseTableOptions, BaseTypeAction } from '../base-table/base-table.model';

export type GenericTableAction =
  | 'add'
  | 'archive'
  | 'cancel'
  | 'copyId'
  | 'delete'
  | 'download'
  | 'duplicate'
  | 'edit'
  | 'icon'
  | 'import'
  | 'reset'
  | 'run'
  | 'unarchive'
  | 'view';

/** non-row related actions */
export const genericTableNonRowActions: GenericTableAction[] = ['add', 'download', 'import'];

export const genericTableOptions: Readonly<Record<'noPagingNoFill', GenericTableOptions>> = {
  noPagingNoFill: {
    paging: 'none',
    noFill: true,
  },
};

interface GenericTableRun_ {
  clearSelections: Subject<void>;
  row: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  rows: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface GenericTableRun extends Partial<GenericTableRun_> {
  action: GenericTableAction;
  rowIndex: number;
}

export type GenericTableOptions = Partial<BaseTableOptions<string, GenericTableAction>>;
export type GenericTableActions = Record<
  GenericTableAction,
  Omit<Required<BaseTypeAction<GenericTableAction>>, 'tooltipCustom'>
>;

export const genericTableActions: GenericTableActions = {
  add: {
    action: 'add',
    icon: 'shared.icon.add',
    tooltip: 'shared.generic-table.tooltip.add',
  },
  archive: {
    action: 'archive',
    icon: 'archive',
    tooltip: 'shared.generic-table.tooltip.archive',
  },
  cancel: {
    action: 'cancel',
    icon: 'shared.icon.cancel',
    tooltip: 'shared.generic-table.tooltip.cancel',
  },
  copyId: {
    action: 'copyId',
    icon: 'shared.icon.copyId',
    tooltip: 'shared.generic-table.tooltip.copyId',
  },
  delete: {
    action: 'delete',
    icon: 'shared.icon.delete',
    tooltip: 'shared.generic-table.tooltip.delete',
  },
  download: {
    action: 'download',
    icon: 'shared.icon.download',
    tooltip: 'shared.generic-table.tooltip.download',
  },
  duplicate: {
    action: 'duplicate',
    icon: 'shared.icon.duplicate',
    tooltip: 'shared.generic-table.tooltip.duplicate',
  },
  edit: {
    action: 'edit',
    icon: 'shared.icon.edit',
    tooltip: 'shared.generic-table.tooltip.edit',
  },
  icon: {
    action: 'icon',
    icon: '',
    tooltip: '',
  },
  import: {
    action: 'import',
    icon: 'cloud_upload',
    tooltip: 'shared.generic-table.tooltip.import',
  },
  unarchive: {
    action: 'unarchive',
    icon: 'unarchive',
    tooltip: 'shared.generic-table.tooltip.unarchive',
  },
  reset: {
    action: 'reset',
    icon: 'shared.icon.reset',
    tooltip: 'shared.generic-table.tooltip.reset',
  },
  run: {
    action: 'run',
    icon: 'shared.icon.run',
    tooltip: 'shared.generic-table.tooltip.run',
  },
  view: {
    action: 'view',
    icon: 'shared.icon.view',
    tooltip: 'shared.generic-table.tooltip.view',
  },
};
