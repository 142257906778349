import { inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { AlgorithmEditComponent } from './algorithm-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { YnComponentModel } from '../../shared/yn/yn.component.model';
import { YnComponent } from '../../shared/yn/yn.component';

@Injectable({
  providedIn: 'root',
})
export class AlgorithmEditGuard {
  private readonly dialog = inject(MatDialog);

  canDeactivate(
    component: AlgorithmEditComponent,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data: YnComponentModel = {
      title: 'hlds.algorithm.changed.title',
      titleTranslate: true,
      message: 'hlds.algorithm.changed.message',
      messageTranslate: true,
      messageType: 'warn',
    };
    return component?.tree.dirty
      ? this.dialog
          .open(YnComponent, { data })
          .afterClosed()
          .pipe(filter((f) => !!f))
      : true;
  }
}
