import { Component, ElementRef, inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NodeBasic } from '@ci';
import { NodeEditComponent } from '../node-edit/node-edit.component';
import { NodeEditCondService } from './node-edit-cond.service';

@Component({
  selector: 'hlds-node-edit-cond',
  templateUrl: './node-edit-cond.component.html',
  styleUrl: './node-edit-cond.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NodeEditCondComponent implements OnInit {
  @ViewChild('inputCond') inputCond!: ElementRef<HTMLInputElement>;

  readonly parent = inject(NodeEditComponent);
  readonly nes: NodeEditCondService = this.parent.nes;

  //  eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  remove(varName: string) {
    const { usedUnused } = this.nes;

    usedUnused.used = usedUnused.used.filter((f) => f !== varName);
  }

  selectTrigger(descendents: NodeBasic[], id: number): string {
    return descendents.find((d) => d.id === id)?.label || '-';
  }

  tabChanged(event: MatTabChangeEvent) {
    const {
      nes: { condition },
    } = this;

    condition.selectedIndex = event.index;
  }

  units(name: string): string {
    return this.parent.data.testRows.find((r) => r.varName == name)?.unit || '';
  }
}
