<hlds-dialog-drawer-close
  [heading]="'hlds.order-interp.title' | translate"
></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col gap-y-4" [formGroup]="form">
  <div>
    <mat-form-field>
      <mat-label>{{ 'hlds.order-disposition.global' | translate }}</mat-label>
      <mat-select>
        @for (disposition of dispositions; track disposition) {
          <mat-option
            [value]="disposition.description"
            (click)="controls.disposition.setValue(disposition.description)"
            >{{ disposition.label }}</mat-option
          >
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'hlds.order-disposition.disposition' | translate }}</mat-label>
      <textarea
        matInput
        [formControl]="controls.disposition"
        [cdkTextareaAutosize]="true"
        required
      ></textarea>
    </mat-form-field>
  </div>
  <div>
    <div>{{ 'hlds.algorithm.tests.title' | translate }}</div>
    <hlds-generic-table
      [rows]="labsModel.labs"
      [items]="labItems"
      [options]="options"
    ></hlds-generic-table>
  </div>
  @if (order.nodeStates.length) {
    <div>
      <div>{{ 'hlds.order.history' | translate }}</div>
      <hlds-generic-table
        [rows]="order.nodeStates"
        [items]="nodeStateItems"
        [options]="options"
      ></hlds-generic-table>
    </div>
  }
  @if (order.nodeNotes.length) {
    <div>
      <div>{{ 'hlds.order.notes' | translate }}</div>
      <hlds-generic-table
        [rows]="order.nodeNotes"
        [items]="nodNotesItems"
        [options]="options"
      ></hlds-generic-table>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-button>
    {{ 'shared.button.print' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="formValue" [disabled]="!form.valid">
    {{ 'shared.button.save' | translate }}
  </button>
</mat-dialog-actions>
