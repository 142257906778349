import { LabelDescriptionModel } from './shared.model';

export type NodeType = 'comment' | 'cond' | 'lab_panel' | 'stop' | 'todo';
export const nodeTypes: NodeType[] = ['comment', 'cond', 'lab_panel', 'stop'];
export type NodeShapeType = Exclude<NodeType, 'todo'>;

/** Custom the look of a displayed node */
export interface NodeCustom {
  fill: string /** badge on top */;
  outline: string /** border */;
}

/** An Edge is a connection to a Vertex. Sort of a child, but not necessarily viewed as such. */
export interface NodeEdge {
  id: number;
  index: number;
  label: string;
}

export interface NodeBase<T extends NodeType> {
  customShape?: NodeCustom;
  /** children */
  edges: NodeEdge[];
  /** Unique  */
  id: number;
  /** x, y drag position */
  loc?: string;
  type: T;
}

export interface NodeLabel<T extends NodeType> extends NodeBase<T>, LabelDescriptionModel {}

/** Gets filled in when the engine is run */
export interface NodeCondEngine {
  condIsTrue: boolean;
  testValues: string[];
}

/** For a cond evaluates to false/true. edge.index = nodeCondEdgeFalse = false, nodeCondEdgeTrue = true */
export type NodeCondEdgeType = 0 | 1;
export const nodeCondEdgeFalse: NodeCondEdgeType = 0;
export const nodeCondEdgeTrue: NodeCondEdgeType = 1;

export interface NodeCond extends Partial<NodeCondEngine>, NodeLabel<'cond'> {
  autoReflex: boolean;
  cond: string;
  varNames: string[];
}

export type NodeCondEqType = 'arithmetic' | 'function' | 'simple';
export const nodeCondEqTypes: NodeCondEqType[] = ['simple', 'arithmetic', 'function'];

export interface NodeComment extends NodeLabel<'comment'> {
  noteRequired: boolean;
}

/** Shared node, mostly for display purposes. See TreeClass.nodeBasic(node) */
export interface NodeBasic {
  id: number;
  type: NodeType;
  label: string;
}

export interface NodeLabPanel
  extends NodeLabel<'lab_panel'>,
    Pick<NodeCond, 'autoReflex' | 'varNames'>,
    Partial<Pick<NodeCondEngine, 'testValues'>> {}

export type NodeStop = NodeLabel<'stop'>;
export type NodeTodo = NodeLabel<'todo'>;
export type NodeAny = NodeComment | NodeCond | NodeLabPanel | NodeStop | NodeTodo;
