import { Component, inject, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { routePath } from '../app-routing/app-routing';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  templateUrl: './root.component.html',
})
export class RootComponent {
  @ViewChild('drawer') drawer!: MatDrawer;
  readonly routePath = routePath;

  private readonly router = inject(Router);
  private readonly utility = inject(UtilsService);

  readonly iframe = this.router.url.includes('iframe=1');

  openStart() {
    const { drawer } = this;
    const paths = this.utility.parseUrl();

    if (paths.length) {
      const path = paths[paths.length - 1];
      if (path.path === routePath.home) {
        drawer.close();
      }
    }
  }
}
