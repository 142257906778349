import { NodeComment, NodeCond, NodeLabPanel, NodeStop } from './node.model';

export type EngineValue = number | string | boolean;
export type EngineRecord = Record<string, EngineValue>;
export type EngineNodeLabPanel = Required<NodeLabPanel>;
export type EngineNodeCond = Required<NodeCond>;

// engineService.run is more restrictive
export type EngineNode = NodeComment | EngineNodeCond | EngineNodeLabPanel | NodeStop;

export interface EngineRunModel {
  engineNodes: EngineNode[];
  error: string;
  orderVarNames: string[];
  orders: (NodeCond | NodeLabPanel)[];
  stopLabels: string[];
  stops: NodeStop[];
}
