import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterpModel } from '@ci';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';

@Component({
  selector: 'hlds-interp-add-edit',
  templateUrl: './interp-add-edit.component.html',
})
export class InterpAddEditComponent extends BaseFormDirective<InterpModel> implements OnInit {
  button = 'shared.button.add';
  readonly data: InterpModel | undefined = inject(MAT_DIALOG_DATA, { optional: true });
  title = 'hlds.interp-add-edit.title';

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const { data } = this;

    this.formCreate({
      label: data?.label || '',
      description: data?.description || '',
    });

    if (data) {
      this.button = 'shared.button.save';
      this.title = 'hlds.interp-add-edit.title-edit';
    }
  }
}
