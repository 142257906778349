import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingsCcaModel } from '../../models/settings-cca.model';
import { SettingsService } from '../../services/settings/settings.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';

@Component({
  selector: 'hlds-settings-cca',
  templateUrl: './settings-cca.component.html',
})
export class SettingsCcaComponent extends BaseFormDirective<SettingsCcaModel> implements OnInit {
  private readonly data = inject(SettingsService).data;

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const { data } = this;

    this.formCreate({
      enabled: data?.cca?.enabled ?? false,
      aptt: data?.cca?.aptt ?? false,
      pt: data?.cca?.pt ?? false,
    });

    const { destroyRef, form } = this;
    const { pt, enabled, aptt } = this.controls;

    const testEnabled = (v: boolean) => {
      if (v) {
        pt.enable();
        aptt.enable();
      } else {
        pt.disable();
        aptt.disable();
      }
    };

    testEnabled(enabled.value);
    enabled.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((v) => testEnabled(v));
    form.valueChanges
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => (data.cca = this.formValue));
  }
}
