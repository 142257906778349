import { inject, Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  SnapshotAction,
} from '@angular/fire/compat/database';
import { OrderAction, OrderModel, OrderNodeState } from '@ci';
import { DateTime } from 'luxon';
import { map, Observable, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef: AngularFireList<OrderModel> = this.fireDb.list('/orders');
  private _rows!: OrderModel[];

  static get emptyOrder(): OrderModel {
    return {
      id: '',
      algorithmId: '',
      disposition: '',
      labsId: '',
      nodeNotes: [],
      nodeStates: [],
      orderState: 'order_in_progress',
      orderStatus: 'order_in_progress_progress',
      updatedAt: '',
      createdAt: '',
    };
  }

  get rows(): OrderModel[] {
    return this._rows;
  }

  actionToNodeState(action: OrderAction): OrderNodeState {
    let result: OrderNodeState;

    switch (action) {
      case 'cancel':
        result = 'node_cancelled';
        break;
      case 'continue':
        result = 'node_active';
        break;
      case 'pause':
        result = 'node_paused';
        break;
    }

    return result;
  }

  getCollection(): Observable<OrderModel[]> {
    const { fireRef } = this;

    if (this.rows) {
      return of(this.rows);
    }

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<OrderModel>[]) =>
        actions.map(
          (action: SnapshotAction<OrderModel>) =>
            ({
              id: action.payload.key,
              ...action.payload.val(),
            }) as OrderModel,
        ),
      ),
      tap((rows) => {
        const date = DateTime.now().toISO();

        this._rows = rows;

        for (const row of rows) {
          row.disposition ||= '';
          row.orderState ||= 'order_in_progress';
          row.orderStatus ||= 'order_in_progress_progress';
          row.createdAt ||= '2024-04-01 08:00';
          row.updatedAt ||= date;

          if (Array.isArray(row.nodeStates)) {
            row.nodeStates.sort((a, b) => b.createdAt.localeCompare(a.createdAt)); // Newest 1st
          } else {
            row.nodeStates = [];
          }

          if (!Array.isArray(row.nodeNotes)) {
            row.nodeNotes = [];
          }
        }
      }),
      take(1),
    );
  }

  update(order: OrderModel) {
    order.updatedAt = DateTime.now().toISO();
    order.nodeStates.sort((a, b) => b.createdAt.localeCompare(a.createdAt)); // Newest 1st

    const o = JSON.parse(JSON.stringify(order)) as OrderModel;

    for (const row of o.nodeStates) {
      delete row.nodeLabel;
    }

    delete o.algorithm;
    delete o.labs;

    this.fireRef.set(o.id, o).then();
  }
}
