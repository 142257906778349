<hlds-dialog-drawer-close
  [heading]="'hlds.order-disposition.action.' + data.action | translate"
></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ 'hlds.order-disposition.global' | translate }}</mat-label>
    <mat-select>
      @for (disposition of dispositions; track disposition) {
        <mat-option
          [value]="disposition.description"
          (click)="controls.disposition.setValue(disposition.description)"
          >{{ disposition.label }}</mat-option
        >
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.order-disposition.disposition' | translate }}</mat-label>
    <textarea matInput [formControl]="controls.disposition" [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="formValue">
    {{ 'shared.button.save' | translate }}
  </button>
</mat-dialog-actions>
