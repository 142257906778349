<hlds-dialog-drawer-close [heading]="'hlds.about.title' | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col">
  <div [innerHTML]="'hlds.about.text' | translate"></div>
  <div translate [translateParams]="{ version: version }">hlds.about.version</div>
  <div class="mt-4 underline flex flex-col w-1/2 items-start">
    <a mat-button [href]="'hlds.about.company.url' | translate" target="_blank">{{
      'hlds.about.company.text' | translate
    }}</a>
    <a mat-button [href]="'hlds.about.help.url' | translate" target="_blank">{{
      'hlds.about.help.text' | translate
    }}</a>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button cdkFocusInitial [mat-dialog-close]="null" mat-flat-button>
    {{ 'shared.button.ok' | translate }}
  </button>
</mat-dialog-actions>
