import {
  CstChildrenDictionary,
  ILexingError,
  IRecognitionException,
  IToken,
} from '@chevrotain/types';
import { Lexer } from 'chevrotain';
import { CondParser } from './cond-parse';
import { condRec, condTokens } from './cond-tokens';

export const allVarNames: string[] = [];
export const allVarNamesLower: string[] = [];

const lexer = new Lexer(condTokens);
const parser = new CondParser();

export interface CondApi {
  children: CstChildrenDictionary;
  errorsLex: ILexingError[];
  errorsParse: IRecognitionException[];
  condition: string;
  conditionCorrected: string;
  conditionRaw: string;
  tokens: IToken[];
  tokenVarNames: IToken[];
  valid: boolean;
  varNames: string[];
  varNamesValid: string[];
}

export const setVarNames = (varNames: string[]) => {
  allVarNames.length = allVarNamesLower.length = 0;

  allVarNames.push(...Array.from(new Set(varNames)));
  allVarNamesLower.push(...allVarNames.map((name) => name.toLowerCase()));
};

export const condParse = (
  condition: string,
  options: { strict: boolean } = { strict: false },
): CondApi => {
  // TODO we can support both &&/AND ||/OR
  // Intentional '&& ' == 'AND' == length 3. Parse positions will stay the same.
  const cond = options.strict ? condition : condition.replace(/and/gi, '&& ').replace(/or/gi, '||');
  const lex = lexer.tokenize(cond);
  const result: CondApi = {
    children: {},
    errorsLex: lex.errors,
    errorsParse: [],
    condition,
    conditionCorrected: condition,
    conditionRaw: cond,
    tokens: lex.tokens || [],
    tokenVarNames: [],
    valid: false,
    varNames: [],
    varNamesValid: [],
  };

  if (lex.errors.length === 0) {
    result.children = parser.go(lex.tokens)?.children || {};
    result.errorsParse = parser.errors;
    result.valid = parser.errors.length === 0;

    // For convenience, save token variables and the corresponding varNames.
    result.tokenVarNames = result.tokens.filter(
      (token) => token.tokenType.name === condRec.identifier.name,
    );
    result.varNames = Array.from(new Set(result.tokenVarNames.map((token) => token.image))).sort();
    result.varNamesValid = result.varNames
      .map((name) => allVarNamesLower.indexOf(name.toLowerCase()))
      .filter((index) => index > -1)
      .map((index) => allVarNames[index])
      .sort();

    // Save correctly as case-sensitive
    result.tokenVarNames.forEach((token) => {
      const index = allVarNamesLower.indexOf(token.image.toLowerCase());

      if (index > -1 && typeof token.startColumn === 'number') {
        result.conditionCorrected =
          result.conditionCorrected.slice(0, token.startColumn - 1) +
          allVarNames[index] +
          result.conditionCorrected.slice(token.endColumn);
      }
    });
  }

  return result;
};
