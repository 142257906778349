import { Component, DestroyRef, inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SnackBarData } from './snack-bar.component.model';

@Component({
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackBarComponent {
  readonly data: SnackBarData = inject(MAT_SNACK_BAR_DATA);
  private readonly destroyRef = inject(DestroyRef);
  private readonly snackRef = inject(MatSnackBarRef<SnackBarComponent>);

  constructor() {
    /*
     * Dismiss snack with any mouse click. Delay a sec, so other click events
     * don't affect it
     */
    setTimeout(() => {
      fromEvent(document, 'click')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.close());
    }, 1000);
  }

  close() {
    this.snackRef.dismiss();
  }
}
