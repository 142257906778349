import { SelectionModel } from '@angular/cdk/collections';
import { Directive, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { HasTableStore } from '../../services/store/store.model';
import { BaseSubscriptionsDirective } from '../base-subscriptions/base-subscriptions.directive';
import { GenericTableStore } from '../generic-store';
import { KeyValueMenuItem } from '../menu-item';
import { AnyString } from '../record-types';
import { BaseTableOptions, BaseTableView } from './base-table.model';

/**
 * This is an abstract class: abstract items: KeyValueMenuItem<ColumnView>[];
 */
@Directive()
export abstract class BaseTableDirective<
    DataModel extends AnyString,
    ColumnView extends string,
    ActionType = string,
  >
  extends BaseSubscriptionsDirective
  implements HasTableStore<ColumnView>, OnInit
{
  readonly baseOptions: Partial<BaseTableOptions<ColumnView, ActionType>> = {};
  readonly dataSource = new MatTableDataSource<DataModel>([]);
  /** standardize on 'select' ...[visible columns] 'action' */
  displayedColumns: (ColumnView | BaseTableView)[] = [];
  /** need to implement */
  abstract items: KeyValueMenuItem<ColumnView>[];
  readonly selection = new SelectionModel<DataModel>(true, []);

  // this is protected b/c it's an abstract class
  protected constructor(baseOptions?: Partial<BaseTableOptions<ColumnView, ActionType>>) {
    super();

    if (baseOptions) {
      Object.assign(this.baseOptions, baseOptions);
    }
  }

  /**
   * the rows that are selected
   */
  get selected(): DataModel[] {
    return this.selection.selected;
  }

  /**
   * set baseOptions.defaultHidden and then get/set
   */
  get tableStore(): GenericTableStore<ColumnView> {
    return null as never;
  }

  /**
   * set baseOptions.defaultHidden and then get/set
   */
  set tableStore(table: GenericTableStore<ColumnView>) {
    throw new Error('set tableStore() needs to be implemented');
  }

  ngOnInit(): void {
    if (this.displayedColumns.length === 0 && this.items.length > 0) {
      this.setDisplayedColumns();
    }
  }

  /**
   * typesafe column in html
   *
   * @param c typesafe column
   * @return typesafe column
   */
  safeCol(c: ColumnView | BaseTableView): ColumnView | BaseTableView {
    return c;
  }

  /**
   * typesafe row in html
   *
   * @param row row in html
   * @return typesafe row
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  safeRow(row: any): DataModel {
    return row;
  }

  selectIsAllSelected(): boolean {
    return this.selected.length === this.dataSource.data.length;
  }

  selectMasterToggle(): void {
    if (this.selectIsAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.data);
    }
  }

  /**
   * Set, reset or set all displayed columns via the "gear" control
   *
   * @param option 'all' | 'set' | 'reset'
   */
  setDisplayedColumns(option: 'all' | 'set' | 'reset' = 'set'): void {
    const { baseOptions, items, destroyRef } = this;
    const { defaultHidden } = baseOptions;
    let columns: (ColumnView | BaseTableView)[] = [];
    const allReset = option === 'all' || option === 'reset';

    // Fail-safe test to make sure this only executes appropriately.
    if (Array.isArray(defaultHidden) && (!items[0].value.formControl || allReset)) {
      const { tableStore: table } = this;

      if (allReset) {
        table.hidden = option === 'all' ? [] : defaultHidden.slice();
        this.tableStore = table;
        this.tableSave();
      }

      for (const item of items) {
        const { value } = item;

        value.hidden = (table.hidden || []).includes(item.key);

        if (allReset) {
          if (value?.formControl) {
            value.formControl.setValue(!value?.hidden, { emitEvent: false });
          }
        } else {
          value.formControl = new FormControl(!value.hidden);

          value.formControl.valueChanges
            .pipe(takeUntilDestroyed(destroyRef))
            .subscribe((v: boolean) => {
              value.hidden = !v;
              table.hidden = items.filter((f) => f.value.hidden).map((f) => f.key);
              this.tableStore = table;
              this.tableSave();
              this.setDisplayedColumns();
            });
        }
      }
    }

    if (baseOptions.select) {
      columns.push('select');
    }

    columns.push(...items.filter((f) => !f.value.hidden).map((f) => f.key));

    if (baseOptions.actions?.length) {
      columns.push('action');
    }

    if (Array.isArray(this.tableStore?.order)) {
      const { tableStore: table } = this;
      const newOrder: (ColumnView | BaseTableView)[] =
        table.order?.filter((o) => columns.includes(o)) || [];

      if (newOrder.length === columns.length) {
        columns = newOrder;
      }
    }

    this.displayedColumns = columns;
  }

  tableSave(): void {
    throw new Error('tableSave() needs to be implemented');
  }

  tableSaveSort(sort: Sort): void {
    const { tableStore } = this;
    const { active, direction } = sort;

    tableStore.sortDirection = direction;
    tableStore.sortColumn = active as any; // eslint-disable-line @typescript-eslint/no-explicit-any

    this.tableSave();
  }
}
