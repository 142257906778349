import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsDispositionModel } from '@ci';

import { BaseFormDirective } from '../../shared/base-form/base-form.directive';

@Component({
  selector: 'hlds-disposition-add-edit',
  templateUrl: './disposition-add-edit.component.html',
})
export class DispositionAddEditComponent
  extends BaseFormDirective<SettingsDispositionModel>
  implements OnInit
{
  button = 'shared.button.add';
  readonly data: SettingsDispositionModel | undefined = inject(MAT_DIALOG_DATA, { optional: true });
  title = 'hlds.disposition-add-edit.title';

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const { data } = this;

    this.formCreate({
      description: data?.description || '',
      label: data?.label || '',
    });

    if (data) {
      this.button = 'shared.button.save';
      this.title = 'hlds.disposition-add-edit.title-edit';
    }
  }
}
