import { Component, inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AlgorithmTreeEditComponentData,
  AlgorithmTreeEditComponentLR,
  AlgorithmTreeEditComponentRes,
} from './algorithm-tree-edit.component.model';

@Component({
  templateUrl: './algorithm-tree-edit.component.html',
})
export class AlgorithmTreeEditComponent implements OnInit {
  readonly data: AlgorithmTreeEditComponentData = inject(MAT_DIALOG_DATA);
  readonly interpretation = new FormControl<boolean>(false, { nonNullable: true });
  readonly lrs: AlgorithmTreeEditComponentLR[] = ['left', 'right'];
  radioCount = 0;
  radios!: AlgorithmTreeEditComponentLR[];
  readonly serial = new FormControl<boolean>(false, { nonNullable: true });

  private readonly dialogRef = inject(MatDialogRef<AlgorithmTreeEditComponent>);

  countRadios() {
    const { radios } = this;
    setTimeout(() => (this.radioCount = radios.filter((radio) => !!radio).length));
  }

  ngOnInit() {
    const {
      data: {
        roots,
        tree: { interpretation, serial },
      },
    } = this;
    this.radios = new Array<AlgorithmTreeEditComponentLR>(roots.length);
    this.radios.fill('' as AlgorithmTreeEditComponentLR);
    this.serial.setValue(!serial);
    this.interpretation.setValue(interpretation);
  }

  save() {
    const {
      dialogRef,
      radios,
      serial,
      interpretation,
      data: { tree },
    } = this;
    const res: AlgorithmTreeEditComponentRes[] = [];

    tree.serial = !serial.value;
    tree.interpretation = interpretation.value;

    radios.forEach((radio, index) => {
      if (radio) {
        res.push({
          lr: radio,
          index,
        });
      }
    });

    dialogRef.close(res);
  }
}
