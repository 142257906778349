import { condParse } from './cond-api';

const tests: string[] = [
  'x > 10',
  '(x > 10)',
  '(x > 10) || y > 5',
  'x > 10 && y > 11 ',
  '(x > 10 && y123 > 11)',
  'x > 10 || y > 11 ',
  '(x > 10 || y > 11)',
  '(a > 1 || b > 2) && (a > 12) && (b > 13)',
  'a > 1 && (b > 2)',
  '(a > 1 || b > 2) && c > 3 && d > 13',
  '(a > 1 || b > 2) && c > 3 && (d > 13)',
  '(a > 1) && c > 3 && d > 13',
  '(PNP>=4&&PNP<=5)',
  '(PT-sec>=14)||(APTT-sec>37)||(dRVVT-S>=1.20)',
  '(PT-sec>=15.8&&PT-sec<=24.9)',
  '(PT-sec>=9.4&&PT-sec<=13.9)&&(APTT-sec>=25&&APTT-sec<=37)&&(dRVVT-S<1.20)',
  '(RT>=14.0&&RT<23.9)',
  '(dRVVT-S-mix<1.20&&dRVVT-C-mix<1.20)||(dRVVT-S-mix>=1.20&&dRVVT-C-mix<1.20)',
  '(dRVVT-S>=1.20&&dRVVT-S>=1.20)||(dRVVT-S-mix<1.20&&dRVVT-s-mix>=1.20)',
  'APTT-sec>37',
  'PT-sec>=14',
  'PT-sec>=24.9',
  'RT>=23.9',
  // lac
  'SCT-C-mix <= 1.16',
  'SCT-S-mix <= 1.16',
  'SCT-C <= 1.16',
  'SCT-S <= 1.16',
  'dRVVT-C-mix <= 1.20',
  'dRVVT-S-mix <= 1.20',
  'dRVVT-C <= 1.2',
  'dRVVT-S <= 1.20',
];

/**
 * Run tests. If any fail, throw an exception.
 */
const testRun = () => {
  let count = 0;

  for (const test of tests) {
    const res = condParse(test, { strict: true });
    if (!res.valid) {
      if (count++ === 0) {
        console.error('        1        2        3        4        5        6        7');
        console.error('123456790123456790123456790123456790123456790123456790123456790');
      }

      if (res.errorsLex.length) {
        console.error(test, 'bad lex', res.errorsLex);
      } else {
        console.error(test, res.errorsParse, res.children);
      }
    }
  }

  if (count) {
    throw new Error(`Parser failed count: ${count}`);
  }
};

testRun();
