import { NgxLoggerLevel } from 'ngx-logger';
import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  baseUrl: '',
  production: false,
  ngxLoggerLevel: NgxLoggerLevel.DEBUG,
  useHash: true,
  firebase: {
    apiKey: 'AIzaSyC1Q9N1mlhmdPDlEoPTvZS9L7VyRrMgPUc',
    databaseURL: 'https://hlds-493b8-default-rtdb.firebaseio.com',
  },
};
