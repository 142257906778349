import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  InterpModel,
  NodeCustom,
  NodeShapeType,
  SettingsDispositionModel,
  TestCustomModel,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { ColorEvent } from 'ngx-color';
import { filter } from 'rxjs';
import { testItems } from '../../algorithm/algorithm-edit/algorithm-edit.component.model';
import { InterpAddEditComponent } from '../../algorithm/interp-add-edit/interp-add-edit.component';
import { SettingsService } from '../../services/settings/settings.service';
import { TestService } from '../../services/test/test.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { GenericTableComponent } from '../../shared/generic-table/generic-table.component';
import {
  genericTableActions,
  GenericTableOptions,
  GenericTableRun,
} from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { DispositionAddEditComponent } from '../disposition-add-edit/disposition-add-edit.component';
import { TestCustomAddEditComponent } from '../test-custom-add-edit/test-custom-add-edit.component';
import { TestCustomAddEditComponentModel } from '../test-custom-add-edit/test-custom-add-edit.component.model';

interface MyForm {
  autoReflex: boolean;
  interpretation: boolean;
  trueLabel: string;
  falseLabel: string;
}

interface DispositionModel {
  items: KeyValueMenuItem<keyof SettingsDispositionModel>[];
  options: GenericTableOptions;
}

interface CustomModel {
  items: KeyValueMenuItem<keyof TestCustomModel>[];
  options: GenericTableOptions;
}

interface Interp {
  items: KeyValueMenuItem<keyof InterpModel>[];
  options: GenericTableOptions;
}

@Component({
  selector: 'hlds-settings-algorithms',
  templateUrl: './settings-algorithms.component.html',
})
export class SettingsAlgorithmsComponent extends BaseFormDirective<MyForm> implements OnInit {
  readonly custom: CustomModel = {
    items: [
      ...testItems(),
      { key: 'query', value: { label: 'hlds.test-custom.table.col.query', unsortable: true } },
      {
        key: 'options',
        value: { label: 'hlds.test-custom.table.col.options', type: 'array', unsortable: true },
      },
    ],
    options: {
      add: true,
      paging: 'none',
      noFill: true,
      actions: [genericTableActions.edit, genericTableActions.delete],
      disableRowClick: true,
      tooltip: {
        add: 'hlds.test-custom.table.add-tooltip',
      },
    },
  };
  readonly blockColors = SettingsService.blockColors;
  readonly data = inject(SettingsService).data;
  readonly disposition: DispositionModel = {
    items: [
      {
        key: 'label',
        value: { label: 'hlds.disposition.table.col.label', unsortable: true },
      },
      {
        key: 'description',
        value: { label: 'hlds.disposition.table.col.description', unsortable: true },
      },
    ],
    options: {
      add: true,
      paging: 'none',
      noFill: true,
      actions: [genericTableActions.edit, genericTableActions.delete],
      disableRowClick: true,
      tooltip: {
        add: 'hlds.disposition.table.add-tooltip',
      },
    },
  };
  readonly interp: Interp = {
    items: [
      {
        key: 'label',
        value: { label: 'hlds.interp.table.col.label', unsortable: true },
      },
      {
        key: 'description',
        value: { label: 'hlds.interp.table.col.description', unsortable: true },
      },
    ],

    options: {
      add: true,
      paging: 'none',
      noFill: true,
      actions: [genericTableActions.edit, genericTableActions.delete],
      disableRowClick: true,
      tooltip: {
        add: 'hlds.interp.table.add-tooltip',
      },
    },
  };

  private readonly dialog = inject(MatDialog);
  private readonly testService = inject(TestService);
  private readonly translate = inject(TranslateService);

  constructor() {
    super();
  }

  actionDisposition(run: GenericTableRun) {
    const row = run.row as SettingsDispositionModel;
    const {
      data: { algorithms },
    } = this;

    switch (run.action) {
      case 'edit':
        this.addEditDisposition(row);
        break;
      case 'add':
        this.addEditDisposition();
        break;
      case 'delete':
        algorithms.dispositions = algorithms.dispositions
          .filter((f) => f.label != row.label)
          .slice(0);
        break;
    }
  }

  actionInterp(run: GenericTableRun) {
    const row = run.row as InterpModel;
    const {
      data: { algorithms },
    } = this;

    switch (run.action) {
      case 'edit':
        this.addEditInterp(row);
        break;
      case 'add':
        this.addEditInterp();
        break;
      case 'delete':
        algorithms.interps = algorithms.interps.filter((f) => f.label != row.label).slice(0);
        break;
    }
  }

  addCustom(run: GenericTableRun) {
    const row = run.row as TestCustomModel;
    const {
      data: { algorithms },
    } = this;

    switch (run.action) {
      case 'edit':
        this.addEditCustom(row);
        break;
      case 'add':
        this.addEditCustom();
        break;
      case 'delete':
        algorithms.testCustoms = algorithms.testCustoms
          .filter((f) => f.varName != row.varName)
          .slice(0);
        break;
    }
  }

  changeComplete(event: ColorEvent, shape: NodeShapeType, color: keyof NodeCustom) {
    const { shapes } = this.data.algorithms;

    shapes[shape][color] = event.color.hex;
  }

  ngOnInit() {
    const {
      data: { algorithms },
      translate,
    } = this;
    let trueLabel = algorithms?.labels?.trueLabel ?? '';
    let falseLabel = algorithms?.labels?.falseLabel ?? '';

    if (!trueLabel) {
      trueLabel = translate.instant('hlds.algorithm-node.true');
    }

    if (!falseLabel) {
      falseLabel = translate.instant('hlds.algorithm-node.false');
    }

    this.formCreate({
      autoReflex: algorithms?.auto?.reflex ?? true,
      interpretation: algorithms?.auto?.interpretation ?? true,
      trueLabel,
      falseLabel,
    });

    const { destroyRef, form } = this;

    form.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
      const {
        formValue: { autoReflex, interpretation, trueLabel, falseLabel },
      } = this;

      algorithms.auto.reflex = autoReflex;
      algorithms.auto.interpretation = interpretation;
      algorithms.labels.trueLabel = trueLabel;
      algorithms.labels.falseLabel = falseLabel;
    });
  }

  reset(shape: NodeShapeType) {
    const { shapes, shapesDefault } = this.data.algorithms;

    shapes[shape] = Object.assign({}, shapesDefault[shape]);
  }

  resetLabels() {
    const {
      controls: { trueLabel, falseLabel },
      translate,
    } = this;

    trueLabel.setValue(translate.instant('hlds.algorithm-node.true'));
    falseLabel.setValue(translate.instant('hlds.algorithm-node.false'));
  }

  private addEditCustom(rec?: TestCustomModel) {
    const {
      dialog,
      destroyRef,
      data: { algorithms },
      testService,
    } = this;
    const data: TestCustomAddEditComponentModel = {
      rec,
      recs: algorithms.testCustoms,
    };
    const config: MatDialogConfig<TestCustomAddEditComponentModel> = {
      data,
      width: '1000px',
      maxWidth: '1000px',
    };

    dialog
      .open<TestCustomAddEditComponent, TestCustomAddEditComponentModel, TestCustomModel>(
        TestCustomAddEditComponent,
        config,
      )
      .afterClosed()
      .pipe(
        filter((f): f is TestCustomModel => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((res: TestCustomModel) => {
        if (rec) {
          const index = algorithms.testCustoms.findIndex((r) => r.varName === rec.varName);
          if (index >= 0) {
            algorithms.testCustoms[index] = res;
          }
        } else {
          algorithms.testCustoms.push(res);
        }

        algorithms.testCustoms = algorithms.testCustoms.slice();
        testService.testCustomChanged();
      });
  }

  private addEditDisposition(row?: SettingsDispositionModel) {
    const {
      dialog,
      destroyRef,
      data: { algorithms },
    } = this;

    dialog
      .open(DispositionAddEditComponent, { data: row, width: '1000px', maxWidth: '1000px' })
      .afterClosed()
      .pipe(
        filter((f): f is SettingsDispositionModel => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((rec: SettingsDispositionModel) => {
        if (row) {
          const index = algorithms.dispositions.findIndex(
            (r) =>
              r[GenericTableComponent.idCol as keyof SettingsDispositionModel] ===
              row[GenericTableComponent.idCol as keyof SettingsDispositionModel],
          );
          if (index >= 0) {
            algorithms.dispositions[index] = rec;
          }
        } else {
          algorithms.dispositions.push(rec);
        }

        algorithms.dispositions = algorithms.dispositions.slice();
      });
  }

  private addEditInterp(row?: InterpModel) {
    const {
      dialog,
      destroyRef,
      data: { algorithms },
    } = this;

    dialog
      .open(InterpAddEditComponent, { data: row, width: '1000px', maxWidth: '1000px' })
      .afterClosed()
      .pipe(
        filter((f): f is InterpModel => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((rec: InterpModel) => {
        if (row) {
          const index = algorithms.interps.findIndex(
            (r) =>
              r[GenericTableComponent.idCol as keyof InterpModel] ===
              row[GenericTableComponent.idCol as keyof InterpModel],
          );
          if (index >= 0) {
            algorithms.interps[index] = rec;
          }
        } else {
          algorithms.interps.push(rec);
        }

        algorithms.interps = algorithms.interps.slice();
      });
  }
}
