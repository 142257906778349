<div
  class="flex flex-row justify-between items-center overflow-hidden"
  [ngClass]="{ 'mr-[16px]': !sidePanel, 'mb-3': pin }"
>
  @if (sidePanel) {
    <h4 class="no-margin">{{ heading }}</h4>
    @if (pin) {
      <button tabindex="-1" (click)="pinned.emit()" mat-icon-button>
        <mat-icon>push_pin</mat-icon>
      </button>
    } @else {
      <button mat-icon-button (click)="closed.emit()">
        <mat-icon>{{ 'shared.icon.close' | translate }} </mat-icon>
      </button>
    }
  } @else {
    <div mat-dialog-title>{{ heading }}</div>
    <button mat-icon-button [mat-dialog-close]="dialogClose">
      <mat-icon>{{ 'shared.icon.close' | translate }} </mat-icon>
    </button>
  }
</div>
