import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { SettingsService } from '../../services/settings/settings.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { BaseTableDirective } from '../base-table/base-table.directive';
import { BaseTableOptions } from '../base-table/base-table.model';
import { KeyValueMenuItem, MenuItemChipType, menuItemRecord } from '../menu-item';
import { AnyString } from '../record-types';
import {
  GenericTableAction,
  GenericTableActions,
  genericTableActions,
  genericTableNonRowActions,
  GenericTableRun,
} from './generic-table.model';

@Component({
  selector: 'hlds-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent
  extends BaseTableDirective<AnyString, string, GenericTableAction>
  implements AfterViewInit
{
  static readonly idCol = '__id';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() disabledIds: string[] = [];
  @Input() disabledMessage = '';
  @Input() idSelected = '';
  @Input() initialSort!: MatSortable;
  @Input({ required: true }) items!: KeyValueMenuItem[];
  @Input() noDataMessage = 'shared.table.no-data';
  @Output() run = new EventEmitter<GenericTableRun>();

  readonly allActions: GenericTableActions = genericTableActions;
  /** Fires for b/e: user clicks to a different page or sorts a column */
  readonly backendGetData$ = new Subject<'page' | 'sort'>();
  readonly clearSelections = new Subject<void>();
  readonly dateFormat = inject(SettingsService).data.i18n.date;
  readonly httpsStarts = 'https://';
  /** The data needs a unique id column. If not given, it will be done automatically */
  idCol = '';
  readonly menuItemRecord = menuItemRecord;
  pageSize = 25;
  private readonly snackBar = inject(SnackBarService);
  private readonly translate = inject(TranslateService);

  constructor() {
    super();
    this.busy.set(true);
  }

  /**
   * The default is to show the action column. If you want the 'select' column or
   * not show the action column, pass, e.g.,  [options="{select: true, action: false}"
   *
   * @param options Partial<BaseTableOptions>
   */
  @Input() set options(options: Partial<BaseTableOptions<string, GenericTableAction>>) {
    if (Number.isInteger(options.pageSize)) {
      this.pageSize = options.pageSize || 25;
    }

    Object.assign(this.baseOptions, options);
  }

  @Input({ required: true }) set rows(rows: Readonly<Readonly<AnyString>[]> | AnyString[]) {
    // Defer to make sure items and options are set
    if (Array.isArray(rows)) {
      setTimeout(() => {
        this.dataSource.data = rows;
        this.#testColumns();
        this.#testNameSelected();
      });
    }
  }

  static chipClass(status: string | undefined, map: Record<string, MenuItemChipType>): string {
    const val: MenuItemChipType = status ? map[status] : undefined;

    return val ? `bg ${val}` : '';
  }

  action(action: GenericTableAction, r: AnyString[] | AnyString): void {
    const { idCol, clearSelections, run } = this;
    let rowIndex = -1;

    if (genericTableNonRowActions.includes(action)) {
      run.emit({ action, clearSelections, rowIndex });
    } else {
      const rows: AnyString[] = Array.isArray(r) ? r : [r];

      this.idSelected = Array.isArray(r) ? '' : r[idCol];

      if (r) {
        rowIndex = this.sortedData().findIndex((d) => d[idCol] === this.idSelected);
      }

      if (action == 'copyId') {
        const ids: string[] = rows.map((r) => r[idCol]);
        this.actionCopyId(ids);
      } else {
        const row = Array.isArray(r) ? undefined : r;

        run.emit({ action, clearSelections, row, rows, rowIndex });
      }
    }
  }

  /**
   * User click on the row. React to it only if there is one action
   *
   * @param row row clicked
   * @param event Event
   */
  action0(row: AnyString, event: Event): void {
    const { disabledIds, idCol } = this;
    const id = row[idCol] || '';
    const { disableRowClick, enableRowClick } = this.baseOptions;
    const actions = this.baseOptions.actions || [];

    if (enableRowClick || !(disabledIds.includes(id) || disableRowClick || actions.length != 1)) {
      event.stopPropagation();
      this.action(enableRowClick ? 'run' : actions[0].action, row);
    }
  }

  actionCopyId(id: string | string[]) {
    const { snackBar, translate } = this;
    const ids: string[] = Array.isArray(id) ? id : [id];

    snackBar.open({
      message: translate.instant('shared.clipboard.copied'),
      messages: ids,
      type: 'success',
    });

    navigator.clipboard.writeText(ids.join(','));
  }

  chipClass(status: string, map: Record<string, MenuItemChipType>): string {
    return GenericTableComponent.chipClass(status, map);
  }

  firstPage() {
    this.paginator.pageIndex = 0;
  }

  ngAfterViewInit(): void {
    const {
      baseOptions,
      dataSource,
      sort,
      clearSelections,
      paginator,
      backendGetData$,
      initialSort,
      destroyRef,
    } = this;
    const { paging } = baseOptions;

    // f/e does the sorting if datasource.sort is defined
    dataSource.sort = paging === 'back-end' ? null : sort;

    if (paging !== 'none') {
      let pageSize = paginator.pageSize;
      const pageSizeChange = (page: PageEvent) => {
        if (page.pageSize !== pageSize) {
          pageSize = page.pageSize;
          this.firstPage();
        }
        if (paging === 'back-end') {
          backendGetData$.next('page');
        }
      };

      if (paging === 'back-end') {
        paginator.page
          .pipe(takeUntilDestroyed(destroyRef))
          .subscribe((page: PageEvent) => pageSizeChange(page));

        sort.sortChange.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
          this.firstPage();
          backendGetData$.next('sort');
        });
      } else {
        dataSource.paginator = paginator;

        paginator.page
          .pipe(takeUntilDestroyed(destroyRef))
          .subscribe((page: PageEvent) => pageSizeChange(page));
        sort.sortChange.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => paginator.firstPage());
      }
    }

    if (initialSort) {
      setTimeout(() => sort.sort(initialSort));
    }

    clearSelections.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => this.selection.clear());
  }

  sortedData(): AnyString[] {
    const { dataSource, sort } = this;
    let result = dataSource.data;

    if (sort?.active && dataSource.sort) {
      result = dataSource.sortData(result, dataSource.sort);
    }

    return result;
  }

  #testColumns(): void {
    const { baseOptions, items, dataSource, httpsStarts, busy, selection } = this;
    const { select, actions, hideEmptyColumns, autoDetectColumnType } = baseOptions;
    const { data } = dataSource;
    const idCol = items.find((i) => i.value.type === 'id' && !i.value.idSecondary);
    const cols: string[] = [];

    // Find the id column, ignoring columns with idSecondary = true
    if (idCol) {
      this.idCol = idCol.key;
    } else {
      // ID column not found: add a "fake" __id column with unique ids
      this.idCol = GenericTableComponent.idCol;
      data.forEach((row, index) => (row[this.idCol] = `id-${index}`));
    }

    items
      .filter(
        (item) =>
          !(
            item.value.hidden ||
            (hideEmptyColumns &&
              data.length &&
              data.map((d) => d[item.key]).every((v) => v === null || v === undefined || v === ''))
          ),
      )
      .forEach((item) => {
        const { key, value } = item;

        cols.push(key);

        if (autoDetectColumnType && !value.type) {
          if (
            data.every((d) => typeof d[key] === 'string') &&
            data.some((d) => d[key].startsWith(httpsStarts))
          ) {
            value.type = 'link';
          } else {
            const isDate = data.every((d) => {
              const v = d[key];
              return (
                v instanceof Date || (typeof v === 'string' && v.trim() && !isNaN(Date.parse(v)))
              );
            });

            if (isDate) {
              value.type = 'date';
            }
          }
        }
      });

    this.displayedColumns = select ? ['select', ...cols] : cols;

    if (actions?.length) {
      this.displayedColumns.push('action');
    }

    selection.clear();
    busy.set(false);
  }

  #testNameSelected() {
    const { idSelected, paginator, dataSource, idCol, baseOptions } = this;

    if (idSelected && baseOptions.paging !== 'none') {
      const rowIndex = dataSource.data.findIndex((r) => r[idCol] === idSelected);

      if (rowIndex >= 0 && rowIndex >= paginator?.pageSize) {
        paginator.pageIndex = Math.floor(rowIndex / paginator.pageSize);
        paginator.page.emit(paginator);

        // TODO Wait a few seconds to find the element
        const timer$ = timer(250, 250).subscribe((count: number) => {
          const element: Element | null = document.getElementById(idSelected);

          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              element.setAttribute('class', `scroll-to ${element.getAttribute('class')}`);
            }, 250);
          }

          if (count > 8 || element) {
            timer$.unsubscribe();
          }
        });
      }
    }
  }
}
