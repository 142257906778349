import { FormControl } from '@angular/forms';
import { NodeStop } from './node.model';
import { LabelDescriptionModel } from './shared.model';

export interface InterpMap extends LabelDescriptionModel {
  groups: number[][];
}

export interface InterpMapping {
  interpEntries: InterpMap[];
}

export interface InterpRichControls {
  groups: FormControl<number[]>[];
  label: FormControl<string>;
  description: FormControl<string>;
}
export interface InterpRichEntry {
  controls: InterpRichControls;
  groups: NodeStop[][];
  triggers: string[];
}

export interface InterpRich {
  richEntries: InterpRichEntry[];
}
