<div [formGroup]="form" class="flex flex-col space-y-2">
  <mat-checkbox [formControl]="controls.enabled"
    >{{ 'hlds.settings.cca.enabled' | translate }}
  </mat-checkbox>
  <div>{{ 'hlds.settings.cca.heading.assay' | translate }}</div>
  <div class="flex flex-col space-y-2 pl-4">
    <mat-checkbox [formControl]="controls.pt"
      >{{ 'hlds.settings.cca.pt' | translate }}
    </mat-checkbox>
    <mat-checkbox [formControl]="controls.aptt"
      >{{ 'hlds.settings.cca.aptt' | translate }}
    </mat-checkbox>
  </div>
</div>
