import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderNodeNote } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { OrderNoteComponentModel } from './order-note.component.model';

@Component({
  templateUrl: './order-note.component.html',
})
export class OrderNoteComponent
  extends BaseFormDirective<Partial<OrderNodeNote>>
  implements OnInit
{
  readonly data: OrderNoteComponentModel = inject(MAT_DIALOG_DATA);
  title!: string;
  private readonly translate = inject(TranslateService);

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const {
      data: { row, node },
      translate,
    } = this;
    const label = node.type === 'cond' ? node.cond : node.label;

    this.title = translate.instant('hlds.order-note.title', { label });

    this.formCreate({
      note: row?.note || '',
    });
  }
}
