import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EngineValue, NodeAny, NodeCond, NodeType, nodeTypes, TreeClass } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import {
  Adornment,
  Diagram,
  GraphLinksModel,
  GraphObject,
  InputEvent,
  Link,
  Margin,
  Node,
  Panel,
  Part,
  Picture,
  Point,
  Routing,
  Shape,
  Size,
  Spot,
  Stretch,
  TextBlock,
  TextFormat,
  TreeArrangement,
} from 'gojs';
import { TreeLayout, WheelMode } from 'gojs/release/go-debug';
import { debounceTime, filter, fromEvent, Subject } from 'rxjs';
import { SettingsService } from '../../services/settings/settings.service';
import { TestService } from '../../services/test/test.service';
import { BaseSubscriptionsDirective } from '../../shared/base-subscriptions/base-subscriptions.directive';

/**
 * gojs node
 */
interface GoNode {
  cond: string;
  description: string;
  fill: string;
  id: number;
  label: string;
  loc: string;
  showHuman?: boolean;
  stroke: string;
  type: NodeType;
  unit: string;
  unitDefault?: string;
}

/**
 * gojs link between GoNodes
 */
interface GoLink {
  from: number;
  label: string;
  to: number;
  dim?: boolean;
}

@Component({
  selector: 'hlds-node-view',
  templateUrl: './node-view.component.html',
})
export class NodeViewComponent extends BaseSubscriptionsDirective implements OnInit, AfterViewInit {
  static readonly rootNodeId = -1;
  @ViewChild('root') root!: ElementRef<HTMLDivElement>;
  @Input() editNode$!: Subject<NodeAny>;
  @Input() nodeHighlight$!: Subject<NodeAny[] | NodeAny>;
  @Input() simHighlight$!: Subject<NodeAny[]>;
  @Input() simValues$!: Subject<Record<string, EngineValue> | null>;
  @Input() traverse = true;
  @Input() treeSerial = false;
  @Input() zoom$!: Subject<void>;

  private readonly color = {
    background: '#ffffff',
    backgrounds: ['#ffffff', '#fff', 'white'],
    edge: {
      arrow: '#080808',
      line: '#080808',
      label: 'black',
    },
    font: 'black',
    root: {
      fill: '#6a6a6a',
      label: '#ffffff',
    },
    sim: '#3f51b5',
    todo: 'green',
  };
  private diagram!: Diagram;
  private readonly rootLabel = '__root_label__';
  private readonly settingsService = inject(SettingsService);
  private readonly algorithms = this.settingsService.data.algorithms;
  private readonly testService = inject(TestService);
  private readonly translate = inject(TranslateService);
  private readonly zone = inject(NgZone);
  private _nodes!: NodeAny[];

  get nodes(): NodeAny[] {
    return this._nodes;
  }

  @Input({ required: true }) set nodes(t: NodeAny[]) {
    this._nodes = t.slice();

    this.#treeShow();
  }

  ngAfterViewInit() {
    const interval = setInterval(() => {
      if (this.root) {
        const { clientWidth, clientHeight } = this.root.nativeElement;

        if (clientHeight > 10 && clientWidth > 10) {
          clearInterval(interval);
          this.#treeInit();
          this.#treeShow();
          this.#subscriptions();
        }
      }
    }, 50);
  }

  ngOnInit() {
    const { zone, destroyRef } = this;

    zone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(debounceTime(300), takeUntilDestroyed(destroyRef))
        .subscribe(() => zone.run(() => setTimeout(() => this.#zoomToFit(), 500)));
    });
  }

  #highlight(nodes: NodeAny[]) {
    const { diagram } = this;

    if (diagram) {
      if (nodes.length) {
        diagram.selectCollection(
          nodes
            .map((n) => n.id)
            .map((id) => diagram.findNodeForKey(id))
            .filter((n): n is Node => !!n),
        );
      } else {
        diagram.clearSelection();
      }
    }
  }

  #icon(src: string, init?: Partial<Picture>): Picture {
    if (!src.includes('.')) {
      src += '.svg';
    }

    const result = new Picture(`./assets/img/node/${src}`, { width: 20, height: 20 });

    if (init) {
      result.set(init);
    }

    return result;
  }

  #nodeEdit(go: GraphObject) {
    const { editNode$, nodes, diagram } = this;
    const id = (go as Node).key as number;

    if (editNode$ && Number.isInteger(id)) {
      const node =
        id === NodeViewComponent.rootNodeId
          ? TreeClass.emptyComment(NodeViewComponent.rootNodeId)
          : TreeClass.nodeFind(nodes, id);

      if (node) {
        editNode$.next(node);
      }
    } else {
      diagram.clearSelection();
    }
  }

  #part(type: NodeType): Part {
    const { color, translate } = this;
    const rootNodeText = translate.instant('hlds.algorithm.rootNode');
    const emptyNode = translate.instant('hlds.algorithm.emptyNode');
    let shape = 'RoundedRectangle';
    const todo = (s: string) => {
      if (s.length) {
        if (s === this.rootLabel) {
          return rootNodeText;
        } else {
          return s;
        }
      } else {
        return emptyNode;
      }
    };
    const todoStroke = (s: string) => {
      if (s.length) {
        if (s === this.rootLabel) {
          return color.root.label;
        } else {
          return color.font;
        }
      } else {
        return color.todo;
      }
    };
    let width = 300;

    switch (type) {
      case 'stop':
        width = 200;
        break;
      case 'cond':
        shape = 'Rectangle';
        width = 500;
        break;
    }

    const margin = 4;
    const panel = new Panel('Vertical', {
      alignment: Spot.Top,
      maxSize: new Size(width, NaN),
    })
      .bind('minSize', 'id', (s: number) =>
        s === NodeViewComponent.rootNodeId ? new Size(75, NaN) : new Size(200, NaN),
      )
      .add(
        // Horizontal badge on top
        new Shape('Rectangle', {
          height: 10,
          strokeWidth: 0,
          stretch: Stretch.Horizontal,
          alignment: Spot.Left,
        })
          .bind(
            'visible',
            'fill',
            (s: string) => !!s && !color.backgrounds.includes(s.toLowerCase()),
          )
          .bind('fill', 'fill', (s: string) => s || color.background),
        // badge
        new Panel('Horizontal', {
          alignment: type === 'stop' ? Spot.Center : Spot.Left,
        })
          .bind('visible', 'id', (s: number) => s !== NodeViewComponent.rootNodeId)
          .bind('margin', 'type', (s: NodeType) => (s === 'stop' ? 0 : margin))
          // Node type icon
          .add(
            this.#icon(type).bind(
              'visible',
              'id',
              (s: number) => s !== NodeViewComponent.rootNodeId,
            ),
            new TextBlock()
              .bind('visible', 'unit', (s) => !!s)
              .bind('text', 'unit')
              .bind('font', 'unit', (s: string) =>
                s.includes('=') ? 'bold 8pt Roboto' : '8pt Roboto',
              )
              .bind('stroke', 'unit', (s: string) => (s.includes('=') ? color.sim : color.font)),
          ),
      );

    switch (type) {
      case 'todo':
      case 'comment':
        panel.add(
          new TextBlock({
            stroke: color.font,
            font: '10pt Roboto',
            margin,
          })
            .bind('text', 'label', todo)
            .bind('stroke', 'label', todoStroke)
            .bind('font', 'id', (s: number) =>
              s === NodeViewComponent.rootNodeId ? '14pt Roboto' : '10pt Roboto',
            ),
        );
        break;
      case 'lab_panel':
      case 'cond':
        panel.add(
          new TextBlock({
            font: '10pt Roboto',
            margin,
          })
            .bind('text', 'cond', todo)
            .bind('stroke', 'cond', todoStroke),
          this.#icon('human', { margin: new Margin(0, 0, 4, 0) }).bind('visible', 'showHuman'),
        );
        break;
      case 'stop':
        panel.add(
          new TextBlock({ textAlign: 'center', margin: new Margin(4, 0, 0, 0) })
            .bind('text', 'label', todo)
            .bind('stroke', 'label', todoStroke),
        );
        break;
    }

    const toolTip = GraphObject.build<Adornment>('ToolTip').add(
      new TextBlock({ margin: 4 })
        .bind('text', 'description')
        .bind('visible', 'description', (s) => !!s),
    );

    return new Node('Auto', {
      click: (_e: InputEvent, go: GraphObject) => this.#nodeEdit(go),
      toolTip,
    })
      .bindTwoWay('location', 'loc', Point.parse, Point.stringify)
      .add(
        new Shape(shape, {
          fill: color.background,
          strokeWidth: 1,
          fromSpot: Spot.BottomSide,
          toSpot: Spot.Top,
        })
          .bind('stroke', 'stroke', (s: string) => s || color.background)
          .bind('fill', 'id', (s: number) =>
            s === NodeViewComponent.rootNodeId ? color.root.fill : color.background,
          )
          .bind('strokeWidth', 'id', (s: number) => (s === NodeViewComponent.rootNodeId ? 0 : 1)),
        panel,
      );
  }

  #simValues(values: Record<string, EngineValue>) {
    const { diagram, nodes } = this;
    const conds = TreeClass.treeFind<NodeCond>(nodes, 'cond');

    for (const cond of conds) {
      const node = diagram.findNodeForKey(cond.id);

      if (node) {
        const vertex = node.data as GoNode;
        const buf: string[] = [];

        for (const varName of cond.varNames) {
          const value = values[varName];
          if (value) {
            buf.push(`${varName}=${value}`);
          }
        }

        vertex.unit = buf.length ? buf.join(', ') : vertex.unitDefault || '';
        node.updateTargetBindings();
      }
    }
  }

  #subscriptions() {
    const { destroyRef, nodeHighlight$, zoom$, simHighlight$, simValues$ } = this;

    if (nodeHighlight$) {
      nodeHighlight$
        .pipe(takeUntilDestroyed(destroyRef))
        .subscribe((nodes: NodeAny[] | NodeAny) =>
          this.#highlight(Array.isArray(nodes) ? nodes : [nodes]),
        );
    }

    if (zoom$) {
      zoom$.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => this.#zoomToFit(1000));
    }

    if (simHighlight$) {
      simHighlight$
        .pipe(takeUntilDestroyed(destroyRef))
        .subscribe((nodes: NodeAny[]) => this.#highlight(nodes));
    }

    simValues$
      .pipe(
        filter((values): values is Record<string, EngineValue> => !!values),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((values: Record<string, EngineValue>) => this.#simValues(values));
  }

  #treeInit() {
    if (this.diagram) {
      return;
    }

    this.diagram = new Diagram(this.root.nativeElement, {
      allowInsert: false,
      allowDelete: false,
      allowMove: this.traverse,
      initialDocumentSpot: Spot.Top,
      initialViewportSpot: Spot.Top,
      layout: new TreeLayout({
        angle: 90,
        arrangement: TreeArrangement.Horizontal,
        nodeSpacing: 20,
        layerSpacing: 50,
        isInitial: true,
      }),
      'animationManager.isInitial': false,
      'undoManager.isEnabled': false,
      'toolManager.mouseWheelBehavior': WheelMode.Zoom,
      /*
      ModelChanged: (e: ChangedEvent) => {
        if (e.isTransactionFinished) {
          this.#modelChanged();
        }
      },
       */
    });

    const { diagram, color } = this;
    const { nodeTemplateMap } = diagram;

    // All node shapes are defined, based on type. nodeTemplates should only fire if there is a bug.
    diagram.nodeTemplate = new Node('Auto').add(new Shape('Rectangle', { fill: 'yellow' }));

    // linkTemplate is how "edges" drawn: the lines/arrows between nodes
    diagram.linkTemplate = new Link({ routing: Routing.AvoidsNodes, corner: 10 }).add(
      new Shape({ stroke: color.edge.line, strokeWidth: 1 }).bind('opacity', 'dim', (s: boolean) =>
        s ? 0.25 : 1,
      ),
      new Shape({
        toArrow: 'Triangle',
        scale: 0.75,
        stroke: color.edge.arrow,
        fill: color.edge.arrow,
      }).bind('opacity', 'dim', (s: boolean) => (s ? 0.25 : 1)),
      new TextBlock({
        spacingAbove: 2,
        spacingBelow: 2,
        background: color.background,
        formatting: TextFormat.None,
        stroke: color.edge.label,
        font: '9pt Roboto ',
      }).bind('text', 'label', (s: string) => (s ? ` ${s} ` : '')),
    );

    // nodeTemplateMap contains the node.type shapes
    for (const type of [...nodeTypes, 'todo']) {
      nodeTemplateMap.add(type, this.#part(type as NodeType));
    }
  }

  /**
   * Using nodes as input populate goNode and goLinks
   *
   * @param nodes input: AnyNode[]
   * @param goNodes output: GoNode[]
   * @param goLinks output: GoLink[]
   * @private
   */
  #treePopulate(nodes: NodeAny[], goNodes: GoNode[], goLinks: GoLink[]) {
    const { settingsService, rootLabel, color, translate, treeSerial, testService } = this;
    const { shapes } = this.algorithms;

    for (const node of nodes) {
      const nodeBase: GoNode = {
        cond: '',
        description: node.description,
        fill: '',
        id: node.id,
        label: node.label || '',
        loc: node.loc || '',
        stroke: '',
        type: node.type,
        unit: '',
      };

      goLinks.push(
        ...node.edges.map((edge) => ({ from: node.id, to: edge.id, label: edge.label })),
      );

      if (node.type === 'todo') {
        nodeBase.stroke = color.todo;
      } else {
        if (node.customShape) {
          nodeBase.stroke = node.customShape.outline;
          nodeBase.fill = node.customShape.fill;
        } else {
          nodeBase.stroke = shapes[node.type].outline;
          nodeBase.fill = shapes[node.type].fill;
        }
      }

      switch (node.type) {
        case 'comment':
          goNodes.push({
            ...nodeBase,
            showHuman: node.noteRequired,
          });

          break;
        case 'cond':
          {
            const unit =
              node.varNames.length === 1
                ? (testService.find(node.varNames[0])?.unit ?? '')
                : node.varNames.join(', ');
            goNodes.push({
              ...nodeBase,
              showHuman: !settingsService.autoReflex(node),
              unit,
              unitDefault: unit,
              cond: node.cond || node.label,
            });
          }
          break;
        case 'lab_panel':
          goNodes.push({
            ...nodeBase,
            cond: node.varNames.join('\n'),
            showHuman: !settingsService.autoReflex(node),
          });
          break;
        default:
          goNodes.push(nodeBase);
          break;
      }
    }

    if (!this.traverse) {
      return;
    }

    // Add "Start" node
    const roots: NodeAny[] = TreeClass.treeRoots(nodes);
    const description =
      roots.length <= 1
        ? ''
        : translate.instant(
            treeSerial
              ? 'hlds.algorithm.tree.trees.serial'
              : 'hlds.algorithm.tree.trees.not-serial',
          );

    goNodes.push({
      cond: '',
      description,
      fill: '',
      id: NodeViewComponent.rootNodeId,
      label: rootLabel,
      loc: '',
      stroke: '',
      type: 'comment',
      unit: '',
    });

    goLinks.push(
      ...roots.map((r) => ({
        from: NodeViewComponent.rootNodeId,
        to: r.id,
        label: '',
      })),
    );
  }

  /**
   * Each time the tree changes, #treeShow is called
   */
  #treeShow() {
    if (!this.diagram) {
      return;
    }

    const { diagram, nodes } = this;
    const goNodes: GoNode[] = [];
    const goLinks: GoLink[] = [];

    this.#treePopulate(nodes, goNodes, goLinks);

    // nodeKeyProperty doesn't work correctly if done in one step
    const model = new GraphLinksModel({
      nodeCategoryProperty: 'type',
      nodeKeyProperty: 'id',
    });
    model.nodeDataArray = goNodes;
    model.linkDataArray = goLinks;

    diagram.model = model;
    this.#zoomToFit();
  }

  #zoomToFit(delay = 250) {
    const { diagram } = this;

    if (diagram?.model) {
      setTimeout(() => diagram.zoomToFit(), delay);
    }
  }
}
