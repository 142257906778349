import { condParse } from '../cond';
import { NodeAny, NodeBasic, NodeComment, NodeCond, NodeLabel, NodeTodo, NodeType, TreeRootModel } from '../models';

export class TreeClass {
  static readonly rootStartId = 1000;

  static get emptyTree(): TreeRootModel {
    return {
      interpretation: true,
      interpMapping: {
        interpEntries: [],
      },
      nodes: [],
      serial: false,
    };
  }

  static emptyBase<T extends NodeType>(type: T, id = 0): NodeLabel<T> {
    return {
      description: '',
      edges: [],
      id,
      label: '',
      type,
    };
  }

  static emptyComment(id?: number): NodeComment {
    return {
      ...TreeClass.emptyBase<'comment'>('comment', id),
      noteRequired: false,
    };
  }

  static emptyCond(id?: number): NodeCond {
    return {
      ...TreeClass.emptyBase<'cond'>('cond', id),
      autoReflex: true,
      cond: '',
      varNames: [],
    };
  }

  static emptyTodo(id?: number): NodeTodo {
    return { ...TreeClass.emptyBase<'todo'>('todo', id) };
  }

  /**
   * Often for display purposes, a "label" needs to be  shown. 'lab_panel' and 'cond' types process differently.
   *
   * @param node NodeAny
   * @return NodeBasic object, hopefully with a filled in label column.
   */
  static nodeBasic(node: NodeAny): NodeBasic {
    const label = node.label || node.description;
    const result: NodeBasic = {
      id: node.id,
      label,
      type: node.type,
    };

    switch (node.type) {
      case 'lab_panel':
        result.label = node.varNames.join(', ') || label;
        break;
      case 'cond':
        result.label = node.cond || label;
        break;
    }

    return result;
  }

  static nodeDelete(nodes: NodeAny[], id: number) {
    const index = nodes.findIndex((node) => node.id === id);

    if (index >= 0) {
      nodes.splice(index, 1);

      const orphan = nodes.find((node) => node.id < TreeClass.rootStartId && TreeClass.nodeOrphan(nodes, node.id));

      if (orphan) {
        TreeClass.nodeDelete(nodes, orphan.id);
      } else {
        TreeClass.treeAddIfEmpty(nodes);
      }
    }
  }

  /**
   * Delete an edge from node. Only if the edgeId node is an orphan is it deleted
   *
   * @param nodes all or the notes
   * @param node node that has the edge
   * @param edgeId edge id
   */
  static nodeEdgeDelete(nodes: NodeAny[], node: NodeAny, edgeId: number) {
    node.edges = node.edges.filter((edge) => edge.id !== edgeId);

    if (TreeClass.nodeOrphan(nodes, edgeId)) {
      TreeClass.nodeDelete(nodes, edgeId);
    }
  }

  static nodeFind(nodes: NodeAny[], idOrNode: number | NodeAny): NodeAny | undefined {
    return typeof idOrNode === 'number' ? nodes.find((node) => node.id === idOrNode) : idOrNode;
  }

  /**
   * An orphan. Is a non-root node that has no parent
   *
   * @param nodes all of the nodes
   * @param idOrNode id or NodeAny
   * @return true if an orphan
   */
  static nodeOrphan(nodes: NodeAny[], idOrNode: number | NodeAny): boolean {
    const id = typeof idOrNode === 'number' ? idOrNode : idOrNode.id;
    return !nodes.find((node) => node.edges.find((edge) => edge.id === id));
  }

  static nodeSafe(node: NodeAny, nodes: NodeAny[]) {
    const cond = node as Partial<NodeCond>;
    const edges = node.edges.slice();

    switch (node.type) {
      case 'cond':
        break;
      case 'comment':
        delete cond.cond;
        delete cond.varNames;
        edges.filter((e) => e.index !== 0).forEach((edge) => TreeClass.nodeEdgeDelete(nodes, node, edge.id));
        break;
      case 'lab_panel':
        delete cond.cond;
        edges.filter((e) => e.index !== 0).forEach((edge) => TreeClass.nodeEdgeDelete(nodes, node, edge.id));
        break;
      case 'stop':
        edges.forEach((edge) => TreeClass.nodeEdgeDelete(nodes, node, edge.id));
        break;
      default:
        delete cond.cond;
        delete cond.varNames;
    }

    TreeClass.treeSafe([node]);
  }

  /**
   * Return a NodeTo object with a unique id
   *
   * @param nodes NodeAny[]
   * @param node Node
   * @param index number
   * @param label string
   * @param clear string
   */
  static nodeTodo(
    nodes: NodeAny[],
    {
      node,
      index,
      label,
      clear,
    }: {
      node?: NodeAny;
      index?: number;
      label?: string;
      clear?: boolean;
    },
  ): NodeTodo {
    const result = TreeClass.emptyTodo(TreeClass.#nodeNewId(nodes));

    if (node) {
      if (!Array.isArray(node.edges) || clear) {
        node.edges = [];
      }

      node.edges.push({ id: result.id, index: index ?? 0, label: label ?? '' });
      nodes.push(result);
    }

    return result;
  }

  static treeAddIfEmpty(nodes: NodeAny[]) {
    if (nodes.length === 0) {
      nodes.push(TreeClass.emptyCond(TreeClass.rootStartId));
    }
  }

  /**
   * Find the ancestors of idOrNode
   *
   * @param nodes all the nodes in the tree
   * @param idOrNode node or node ID
   * @param includeNode if true, then include the node in the result
   * @return the ancestors with the root at top
   */
  static treeAncestors(nodes: NodeAny[], idOrNode: number | NodeAny, includeNode = false): NodeAny[] {
    const node = TreeClass.nodeFind(nodes, idOrNode);
    let result: NodeAny[] = node && includeNode ? [node] : [];

    if (!node) {
      return result;
    }

    this.#treeAncestors(nodes, node.id, result);
    result.reverse(); // With one child one parent, reversing will put the root at the top (index = 0).

    const roots = TreeClass.treeRoots(nodes);
    const index = result.findIndex((node) => roots.find((root) => root.id == node.id));

    if (index > 0) {
      result = [result[index], ...result]; // put the root at the top
      result.splice(index + 1, 1); // nuke the old value
    }

    return result;
  }

  static treeDescendents(nodes: NodeAny[], idOrNode: number | NodeAny, includeNode = false): NodeAny[] {
    const node = TreeClass.nodeFind(nodes, idOrNode);
    const result: NodeAny[] = [];

    if (!node) {
      return result;
    }

    if (includeNode) {
      result.push(node);
    }

    TreeClass.#nodesDescendents(nodes, node, result);

    return result;
  }

  static treeDescendentsConds(nodes: NodeAny[], id: number | NodeAny): NodeCond[] {
    return TreeClass.treeFind<NodeCond>(TreeClass.treeDescendents(nodes, id, true), 'cond');
  }

  /**
   * Return nodes that can be safe descendents of idOrNode
   *
   * @param nodes all the nodes in the tree
   * @param idOrNode id or Node
   * @return nodes that are safe
   */
  static treeDescendentsSafe(nodes: NodeAny[], idOrNode: number | NodeAny): NodeAny[] {
    const node = TreeClass.nodeFind(nodes, idOrNode);
    const result: NodeAny[] = [];

    if (!node) {
      return result;
    }

    const edgeIds = node.edges.map((edge) => edge.id); // don't include immediate descendents
    const descend = TreeClass.treeDescendents(nodes, node).filter((n) => !edgeIds.includes(n.id));
    const allIds = [
      node.id,
      ...descend.map((n) => n.id),
      ...TreeClass.treeAncestors(nodes, node).map((n) => n.id),
      ...edgeIds,
    ];

    for (const n of [...descend, ...nodes.filter((n) => !allIds.includes(n.id))]) {
      if (!result.find((res) => res.id === n.id)) {
        result.push(n);
      }
    }

    return result;
  }

  static treeFind<T extends NodeAny>(nodes: NodeAny[], type: NodeType): T[] {
    return nodes.filter((node): node is T => node.type === type);
  }

  static treeFindWithVarName(nodes: NodeAny[], varName: string): NodeCond[] {
    return TreeClass.treeFind<NodeCond>(nodes, 'cond').filter((node) => node.varNames.includes(varName));
  }

  /**
   * Return the top most parents
   *
   * @param nodes NodeAny[]
   */
  static treeRoots(nodes: NodeAny[]): NodeAny[] {
    return nodes.filter((node) => node.id >= TreeClass.rootStartId);
  }

  static treeSafe(nodes: NodeAny[]) {
    for (const node of nodes) {
      node.label ||= '';
      node.description ||= '';
      node.edges = Array.isArray(node.edges)
        ? node.edges.filter((edge) => !!edge).sort((a, b) => a.index - b.index)
        : [];

      if (node.type === 'cond' || node.type === 'lab_panel') {
        if (!Array.isArray(node.varNames)) {
          node.varNames = [];
        }

        if (node.type === 'cond') {
          delete node.testValues;
        }
      }
    }
  }

  /**
   * Used in simulations: navigate the left-right, top-down
   * @param nodes list of nodes
   * @param varNames optional list of variable names
   */
  static treeSortTests(nodes: NodeAny[], varNames?: string[]): string[] {
    const result = new Set<string>();

    for (const root of TreeClass.treeRoots(nodes)) {
      for (const cond of TreeClass.treeDescendentsConds(nodes, root)) {
        for (const varName of cond.varNames) {
          if (!varNames || varNames.includes(varName)) {
            result.add(varName);
          }
        }
      }
    }

    return Array.from(result);
  }

  static #nodeNewId(nodes: NodeAny[]): number {
    for (let result = 1; ; ++result) {
      if (!TreeClass.nodeFind(nodes, result)) {
        return result;
      }
    }
  }

  static #nodesDescendents(nodes: NodeAny[], node: NodeAny, result: NodeAny[]) {
    const matches = node.edges
      .map((edge) => edge.id)
      .map((id) => TreeClass.nodeFind(nodes, id))
      .filter((n): n is NodeAny => !!n);

    for (const match of matches) {
      result.push(match);
      TreeClass.#nodesDescendents(nodes, match, result);
    }
  }

  /**
   * Recursively find ancestors. A node can have many parents.

   * @param nodes all the nodes in the tree
   * @param id to find the parents of
   * @param result the list of ancestors
   */
  static #treeAncestors(nodes: NodeAny[], id: number, result: NodeAny[]) {
    const parents = nodes.filter((node) => node.edges.find((edge) => edge.id === id));

    for (const parent of parents) {
      // Prevent dupes
      if (!result.find((r) => r.id === parent.id)) {
        result.push(parent);
        TreeClass.#treeAncestors(nodes, parent.id, result);
      }
    }
  }

  /**
   * Validate a condition using the lexer. Also confirm that the test variables are valid.
   *
   * @param variables node.varNames
   * @param condition node.cond
   * @return null if valid, object with error info o/w
   */
  conditionValidator(
    variables: string[],
    condition: string,
  ): { text: string; ids?: string[]; noTranslate?: boolean } | null {
    if (condition.trim().length === 0) {
      return { text: 'hlds.node-edit.type.cond.error.empty' };
    }

    const parse = condParse(condition, { strict: false });
    const conditionIdsFound = parse.varNames.map((v) => v.toLowerCase()).sort();

    variables = variables.map((id) => id.toLowerCase());
    let notFound = conditionIdsFound.filter((id) => !variables.includes(id));

    if (notFound.length) {
      return {
        text: 'hlds.node-edit.type.cond.error.tests-condition-not-found',
        ids: notFound,
      };
    }

    if (!parse.valid) {
      return {
        text: [...parse.errorsLex.map((p) => p.message), ...parse.errorsParse.map((p) => p.message)].join(', '),
        noTranslate: true,
      };
    }

    return null;
  }
}
