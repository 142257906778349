import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { localStorageKeys } from '../../local-storage-keys';
import { Language } from '../../models/i18n.model';
import { SettingsService } from '../settings/settings.service';

interface I18n {
  languages: Language[];
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private readonly settingsService = inject(SettingsService);
  private readonly http = inject(HttpClient);
  private readonly translate = inject(TranslateService);
  private _languages: Language[] = [{ label: 'English', ext: 'en' }];
  private _ext = 'en';

  get ext(): string {
    return this._ext;
  }

  set ext(v: string) {
    this._ext = v;
  }

  get languages(): Language[] {
    return this._languages;
  }

  getLanguages(): Observable<Language[]> {
    const settingsDefault = this.settingsService.data.i18n.language;

    return this.http.get<I18n>('./assets/translate/i18n.json').pipe(
      map((i18n) => i18n.languages),
      switchMap((languages) => {
        const { translate, ext: curExt } = this;
        const ext = localStorage.getItem(localStorageKeys.i18n) || settingsDefault || curExt;

        this._languages = languages;
        translate.addLangs(languages.map((l) => l.ext));

        if (ext !== curExt && languages.find((l) => l.ext === ext)) {
          this.ext = ext;
          return translate.use(ext).pipe(map(() => languages));
        } else {
          return of(languages);
        }
      }),
    );
  }
}
