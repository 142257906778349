import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '../../services/settings/settings.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import {
  OrderDispositionComponentModel,
  OrderDispositionComponentRes,
} from './order-disposition.component.model';

@Component({
  templateUrl: './order-disposition.component.html',
})
export class OrderDispositionComponent
  extends BaseFormDirective<OrderDispositionComponentRes>
  implements OnInit
{
  readonly dispositions = inject(SettingsService).data.algorithms.dispositions;
  data: OrderDispositionComponentModel = inject(MAT_DIALOG_DATA);

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    this.formCreate({
      disposition: '',
    });
  }
}
