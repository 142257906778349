import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

import { TestModel } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { TestService } from '../../services/test/test.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';

interface MyFormCreate {
  testName: [string, [Validators]] | string;
}

interface MyForm extends MyFormCreate {
  testName: string;
}

@Component({
  selector: 'hlds-test-add',
  templateUrl: './test-add.component.html',
})
export class TestAddComponent extends BaseFormDirective<MyFormCreate, MyForm> implements OnInit {
  @ViewChild('testNameInput') testNameInput!: ElementRef<MatInput>;
  @Output() varNamesAdded = new EventEmitter<string[]>();

  allTests!: Readonly<TestModel[]>;
  readonly ids: string[] = [];
  isDialog = true;
  readonly tests = signal<Readonly<TestModel[]>>([]);
  title = '';
  private excludeVarNames: string[] = inject(MAT_DIALOG_DATA, { optional: true }) || [];
  private readonly testService = inject(TestService);
  private readonly translate = inject(TranslateService);

  constructor() {
    super();
  }

  @Input() set varNamesToExclude(names: string[]) {
    const firstTime = this.isDialog;

    this.isDialog = false;
    this.excludeVarNames = names;

    if (firstTime) {
      setTimeout(() => {
        if (this.testNameInput) {
          this.testNameInput.nativeElement.focus();
        }
      }, 500);
    }
  }

  addTest() {
    const {
      ids,
      allTests,
      controls: { testName },
      varNamesAdded,
    } = this;
    const varName = testName.value;

    if (allTests.find((a) => a.varName === varName)) {
      varNamesAdded.emit([varName]);
      ids.push(varName);
      ids.sort();
      this.#setTests();

      testName.setValue('');
      testName.setErrors(null);
      this.form.setErrors({ dummy: '' });
    }
  }

  ngOnInit() {
    this.#setTests();
    this.formCreate({ testName: ['', [Validators.required]] });

    const {
      controls: { testName },
      tests,
      destroyRef,
    } = this;

    testName.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((value) => {
        const v = value.toLowerCase().trim();
        const { allTests, isDialog, varNamesAdded } = this;

        if (!v) {
          tests.set(allTests);
        } else {
          tests.set(
            allTests.filter(
              (u) => u.varName.toLowerCase().includes(v) || u.description.toLowerCase().includes(v),
            ),
          );
          const test = allTests.find((a) => a.varName.toLowerCase() === v);

          testName.setErrors(test ? null : { notFound: '' });

          if (test) {
            testName.setValue(test.varName, { emitEvent: false });
            if (!isDialog) {
              varNamesAdded.emit([test.varName]);
            }
          }
        }
      });
  }

  removeId(index: number) {
    this.ids.splice(index, 1);
    this.#setTests();
  }

  #setTests() {
    const { testService, excludeVarNames, ids, translate } = this;
    const count = ids.length ? ids.length.toString() + ' ' : '';

    this.allTests = testService.rows
      .filter((r) => ![...ids, ...excludeVarNames].includes(r.varName))
      .sort();
    this.title = translate.instant('hlds.algorithm-edit-test-add.title-count', { count });
  }
}
