import { inject, Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  SnapshotAction,
} from '@angular/fire/compat/database';
import { setVarNames, TestModel } from '@ci';
import { map, Observable, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  private baseRows!: TestModel[];
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef: AngularFireList<TestModel> = this.fireDb.list('/tests');
  private readonly seeingsService = inject(SettingsService);
  private _rows!: TestModel[];

  get rows(): Readonly<TestModel[]> {
    return this._rows;
  }

  getCollection(): Observable<Readonly<TestModel[]>> {
    const { fireRef } = this;

    if (this.rows) {
      return of(this.rows);
    }

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<TestModel>[]) =>
        actions.map(
          (action: SnapshotAction<TestModel>) =>
            ({
              ...action.payload.val(),
            }) as TestModel,
        ),
      ),
      tap((rows) => {
        this.baseRows = rows;
        this.testCustomChanged();
      }),
      take(1),
    );
  }

  find(name: string): TestModel | undefined {
    name = typeof name === 'string' ? name.trim().toLowerCase() : '';

    return this.rows.find((row) => row.varName.toLowerCase() == name);
  }

  testCustomChanged() {
    if (this.baseRows) {
      this._rows = [...this.baseRows, ...this.seeingsService.data.algorithms.testCustoms].sort(
        (a, b) => a.varName.localeCompare(b.varName) || b.category.localeCompare(a.category),
      );
    }

    setVarNames(this._rows.map((r) => r.varName));
  }
}
