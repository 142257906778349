import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * [a, b, c] => /a/b/c
 *
 * @param additional e.g., a, b, c
 * @return /a/b/c
 */
export const additionalToUrl = (...additional: Array<string | number>): string => {
  let result = '';

  if (additional.length > 0) {
    /*
     * Look for a null/undefined index, e.g,
     * [a, b, null, c, d] or [a, b, '', c, d]
     * terminateIndex = 2
     */
    const terminateIndex = additional.findIndex(
      (a) => !(typeof a === 'number' || (typeof a === 'string' && a.trim().length)),
    );

    // If terminateIndex = 0, then the 1st value is null or blank, so no output
    if (terminateIndex != 0) {
      if (terminateIndex === -1) {
        result = '/' + additional.join('/'); // all values are good;
      } else {
        result = '/' + additional.slice(0, terminateIndex).join('/');
      }
    }
  }

  return result;
};

/**
 * (http://localhost:1234, [a, b, c]) => http://localhost:1234/a/b/c
 *
 * @param base e.g, http://localhost:1234 No trailing slash!
 * @param additional e.g., a, b, c
 * @return http://localhost:1234/a/b/c
 */
export const toUrl = (base: string, ...additional: Array<string | number>): string => {
  const additionalPath = additionalToUrl(...additional);

  return additionalPath ? `${base}${additionalPath}` : base;
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  static isLocal = () =>
    location.host.includes('localhost'); /** cute way of knowing the app in running locally */

  get baseUrl(): string {
    return environment.baseUrl;
  }

  full(url: string | Array<string | number>): string {
    const https = 'https://';

    if (Array.isArray(url)) {
      return typeof url[0] === 'string' && url[0].startsWith(https)
        ? toUrl(url[0], ...url.slice(1))
        : toUrl(this.baseUrl, ...url);
    } else {
      return url.startsWith(https) ? url : toUrl(this.baseUrl, url);
    }
  }
}
