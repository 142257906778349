import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  condParse,
  NodeBasic,
  NodeComment,
  NodeCond,
  nodeCondEdgeFalse,
  nodeCondEdgeTrue,
  NodeCondEdgeType,
  NodeCustom,
  NodeLabPanel,
  NodeShapeType,
  NodeType,
  nodeTypes,
  TreeClass,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { ColorEvent } from 'ngx-color';
import { SettingsService } from '../../services/settings/settings.service';
import { TestService } from '../../services/test/test.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { NodeEditCondService } from '../node-edit-cond/node-edit-cond.service';
import { NodeEditComponentData } from './node-edit.component.model';

interface MyForm {
  autoReflex: boolean;
  cFalse: boolean;
  cTrue: boolean;
  customFill: string;
  customOutline: string;
  description: string;
  label: string;
  labelTrue: string;
  labelFalse: string;
  linkTrue: number;
  linkFalse: number;
  noteRequired: boolean;
  type: NodeType;
}

interface FalseTrue {
  control: {
    enable: FormControl<boolean>;
    edge: FormControl<string>;
    link: FormControl<number>;
  };
  descendents: NodeBasic[];
  index: NodeCondEdgeType;
  label: {
    enable: string;
    edge: string;
    link: string;
  };
}

@Component({
  templateUrl: './node-edit.component.html',
  providers: [NodeEditCondService],
})
export class NodeEditComponent extends BaseFormDirective<MyForm> implements OnInit {
  readonly allTests = inject(TestService).rows;
  readonly blockColors = SettingsService.blockColors;
  readonly data: NodeEditComponentData = inject(MAT_DIALOG_DATA);
  readonly nodeTypes: NodeType[] = nodeTypes;
  falseTrue!: FalseTrue[];
  readonly nes = inject(NodeEditCondService);

  private readonly dialogRef = inject(MatDialogRef<NodeEditComponent>);
  private readonly translate = inject(TranslateService);
  private readonly settingsService = inject(SettingsService);
  private readonly shapes = this.settingsService.data.algorithms.shapes;

  constructor() {
    super({ nonNullable: true });
  }

  actionDelete() {
    const { dialogRef, data } = this;

    data.action = 'delete';
    dialogRef.close(data);
  }

  actionSave() {
    const {
      dialogRef,
      data,
      data: { node, nodes },
      formValue: {
        autoReflex,
        cFalse,
        cTrue,
        customFill,
        customOutline,
        description,
        label,
        labelFalse,
        labelTrue,
        linkFalse,
        linkTrue,
        noteRequired,
        type,
      },
      nes,
    } = this;

    data.action = 'save';
    node.type = type;
    node.label = label;
    node.description = description;

    delete node.customShape;

    switch (type) {
      case 'comment':
        {
          const nodeComment = node as NodeComment;

          if (nodeComment.edges.length != 1) {
            TreeClass.nodeTodo(nodes, { node, clear: true });
          }
          nodeComment.noteRequired = noteRequired;
        }
        break;
      case 'cond':
        {
          const nodeCond = node as NodeCond;
          const parse = condParse(nes.controls.cond.value, { strict: false });
          const edgeFalse = node.edges.find((c) => c.index === nodeCondEdgeFalse);
          const edgeTrue = node.edges.find((c) => c.index === nodeCondEdgeTrue);

          nodeCond.autoReflex = autoReflex;
          nodeCond.varNames = parse.varNamesValid;
          nodeCond.cond = parse.conditionCorrected;

          if (cFalse && linkFalse > 0 && !(edgeFalse?.id === linkFalse)) {
            if (edgeFalse) {
              TreeClass.nodeEdgeDelete(nodes, node, edgeFalse.id);
            }
            node.edges.push({ id: linkFalse, label: labelFalse, index: nodeCondEdgeFalse });
          } else if (cFalse != !!edgeFalse) {
            if (edgeFalse) {
              TreeClass.nodeEdgeDelete(nodes, node, edgeFalse.id);
            } else {
              TreeClass.nodeTodo(nodes, { node, index: nodeCondEdgeFalse, label: labelFalse });
            }
          }

          if (cTrue && linkTrue > 0 && !(edgeTrue?.id === linkTrue)) {
            if (edgeTrue) {
              TreeClass.nodeEdgeDelete(nodes, node, edgeTrue.id);
            }
            node.edges.push({ id: linkTrue, label: labelTrue, index: nodeCondEdgeTrue });
          } else if (cTrue != !!edgeTrue) {
            if (edgeTrue) {
              TreeClass.nodeEdgeDelete(nodes, node, edgeTrue.id);
            } else {
              TreeClass.nodeTodo(nodes, { node, index: nodeCondEdgeTrue, label: labelTrue });
            }
          }

          for (const edge of nodeCond.edges) {
            edge.label = edge.index === nodeCondEdgeFalse ? labelFalse : labelTrue;
          }
        }
        break;
      case 'lab_panel': {
        const nodeLabPanel = node as NodeLabPanel;

        nodeLabPanel.autoReflex = autoReflex;
        nodeLabPanel.varNames = nes.usedUnused.used;

        if (nodeLabPanel.edges.length != 1) {
          TreeClass.nodeTodo(nodes, { node, clear: true });
        }
      }
    }

    if (type !== 'todo') {
      const shape = this.shapes[type];

      if (!(customOutline === shape.outline && shape.fill === customFill)) {
        node.customShape = {
          fill: customFill,
          outline: customOutline,
        };
      }
    }

    dialogRef.close(data);
  }

  customChange(event: ColorEvent, nodeShape: keyof NodeCustom) {
    const {
      controls: { customFill, customOutline },
    } = this;
    const color = event.color.hex;

    switch (nodeShape) {
      case 'fill':
        customFill.setValue(color);
        break;
      case 'outline':
        customOutline.setValue(color);
        break;
    }
  }

  customReset() {
    const { customFill, customOutline, type } = this.controls;

    if (type.value !== 'todo') {
      const shape = this.shapes[type.value as NodeShapeType];
      customFill.setValue(shape.fill);
      customOutline.setValue(shape.outline);
    }
  }

  ngOnInit() {
    const {
      data: { node, nodes },
      destroyRef,
      settingsService: {
        data: {
          algorithms: {
            labels,
            auto: { reflex },
          },
        },
      },
      translate,
      nes,
    } = this;

    nes.parent = this;
    this.formCreate({
      autoReflex: true,
      customFill: '',
      customOutline: '',
      cFalse: false,
      cTrue: false,
      description: '',
      label: '',
      labelFalse: labels.falseLabel || translate.instant('hlds.algorithm-node.false'),
      labelTrue: labels.trueLabel || translate.instant('hlds.algorithm-node.true'),
      linkFalse: -1,
      linkTrue: -1,
      noteRequired: false,
      type: node.type,
    });

    const {
      controls: {
        autoReflex,
        cFalse,
        cTrue,
        customFill,
        customOutline,
        description,
        label,
        labelFalse,
        labelTrue,
        linkFalse,
        linkTrue,
        noteRequired,
        type,
      },
      form,
    } = this;
    const setCustom = () => {
      if (type.value !== 'todo') {
        if (node.customShape) {
          const { fill, outline } = node.customShape;
          customFill.setValue(fill || '');
          customOutline.setValue(outline || '');
        } else {
          this.customReset();
        }
      }
    };
    const descendents = nes.descendents(nodes, node);
    this.falseTrue = [
      {
        control: {
          enable: cFalse,
          edge: labelFalse,
          link: linkFalse,
        },
        descendents,
        index: nodeCondEdgeFalse,
        label: {
          enable: 'hlds.node-edit.type.cond.has-false',
          edge: 'hlds.node-edit.type.cond.label-false',
          link: 'hlds.node-edit.type.cond.link-false',
        },
      },
      {
        control: {
          enable: cTrue,
          edge: labelTrue,
          link: linkTrue,
        },
        descendents,
        index: nodeCondEdgeTrue,
        label: {
          enable: 'hlds.node-edit.type.cond.has-true',
          edge: 'hlds.node-edit.type.cond.label-true',
          link: 'hlds.node-edit.type.cond.link-true',
        },
      },
    ];

    label.setValue(node.label);
    description.setValue(node.description);
    setCustom();

    switch (node.type) {
      case 'cond':
      case 'comment':
      case 'lab_panel':
        if (node.type === 'cond' || node.type === 'lab_panel') {
          if (!Array.isArray(node.varNames)) {
            node.varNames = [];
          }

          autoReflex.setValue(node.autoReflex ?? reflex);
          nes.setUsedUnused(node.varNames);
        }

        if (node.type === 'comment' || node.type === 'lab_panel') {
          cTrue.setValue(true);
        }
        break;
    }

    switch (node.type) {
      case 'cond':
        cFalse.setValue(node.edges.some((edge) => edge.index === nodeCondEdgeFalse));
        cTrue.setValue(node.edges.some((edge) => edge.index === nodeCondEdgeTrue));
        nes.controls.cond.setValue(node.cond);

        for (const { id, index, label } of node.edges) {
          const n = TreeClass.nodeFind(nodes, id);

          if (n) {
            if (!descendents.find((node) => node.id === n.id)) {
              this.falseTrue[index].descendents = [TreeClass.nodeBasic(n), ...descendents];
            }

            this.falseTrue[index].control.link.setValue(n.id);
          }

          switch (index) {
            case nodeCondEdgeFalse:
              labelFalse.setValue(label || labels.falseLabel);
              break;
            case nodeCondEdgeTrue:
              labelTrue.setValue(label || labels.trueLabel);
              break;
          }
        }

        // If both are false, override.
        if (!(cFalse.value || cTrue.value)) {
          cFalse.setValue(true);
          cTrue.setValue(true);
        }
        break;
      case 'comment':
        noteRequired.setValue(node?.noteRequired ?? false);
        break;
      case 'todo':
        cTrue.setValue(true);
        cFalse.setValue(true);
        break;
    }

    form.markAllAsTouched();

    type.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((nodeType: NodeType) => {
      switch (nodeType) {
        case 'comment':
          cTrue.setValue(true);
          break;
        case 'cond':
        case 'lab_panel':
          nes.controls.cond.setValue('');
          autoReflex.setValue(reflex);
          cTrue.setValue(true);
          cFalse.setValue(true);
          nes.setUsedUnused([]);
          break;
      }
      setCustom();
    });
  }
}
