import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestCustomModel, TestUnitType, testUnitTypes } from '@ci';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { TestCustomAddEditComponentModel } from './test-custom-add-edit.component.model';

interface MyForm extends Omit<TestCustomModel, 'options'> {
  option: string;
}

@Component({
  templateUrl: './test-custom-add-edit.component.html',
})
export class TestCustomAddEditComponent extends BaseFormDirective<MyForm> implements OnInit {
  button = 'shared.button.add';
  readonly testUnitTypes = testUnitTypes;
  readonly data: TestCustomAddEditComponentModel = inject(MAT_DIALOG_DATA);
  options: string[] = [];
  title = 'hlds.test-custom.add-edit.title-add';

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const {
      data: { rec },
    } = this;

    this.formCreate({
      description: rec?.description || '',
      varName: rec?.varName || '',
      option: '',
      query: rec?.query || '',
      category: rec?.category || '',
      unit: rec?.unit || '',
      unitType: rec?.unitType || ('' as TestUnitType),
    });

    if (rec) {
      if (Array.isArray(rec.options)) {
        this.options = rec.options;
      }
      this.button = 'shared.button.save';
      this.title = 'hlds.test-custom.add-edit.title-edit';
    }
  }

  optionAdd() {
    const { option } = this.controls;

    this.options.push(option.value);
    option.setValue('');
  }

  optionDelete(option: string) {
    this.options = this.options.filter((o) => option !== o);
  }

  save(): TestCustomModel {
    return {
      ...this.formValue,
      options: this.options,
    };
  }
}
