import { Component, EventEmitter, Input, Output } from '@angular/core';
import { routePath } from '../app-routing/app-routing';

interface Item {
  label: string;
  route: string[];
}

@Component({
  selector: 'hlds-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  @Input() drawer = false;
  @Output() routed = new EventEmitter<void>();
  readonly home: Item = { label: 'hlds.home.title', route: ['/', routePath.home] };
  readonly items: Item[] = [
    { label: 'hlds.algorithms.title', route: ['/', routePath.algorithms] },
    { label: 'hlds.order-list.title', route: ['/', routePath.orders] },
    { label: 'hlds.settings.title', route: ['/', routePath.settings] },
  ];
}
