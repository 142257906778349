import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Can be used in a dialog and mat-drawer
 */
@Component({
  selector: 'hlds-dialog-drawer-close',
  templateUrl: './dialog-drawer-close.component.html',
})
export class DialogDrawerCloseComponent {
  @Output() closed = new EventEmitter<void>();
  @Output() pinned = new EventEmitter<void>();
  @Input() sidePanel = false;
  @Input() dialogClose = null;
  @Input() heading = '';
  @Input() pin = false;
}
