<hlds-dialog-drawer-close [heading]="title"></hlds-dialog-drawer-close>
<mat-dialog-content>
  <span class="{{ messageType }}" [innerHTML]="message"></span>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button cdkFocusInitial [mat-dialog-close]="null" mat-button>
    {{ 'shared.button.no' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="true">
    {{ 'shared.button.yes' | translate }}
  </button>
</mat-dialog-actions>
