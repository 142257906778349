import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ColorBlockModule } from 'ngx-color/block';
import { LoggerModule } from 'ngx-logger';
import { MaterialModule } from '../material.module';
import { DialogDrawerCloseComponent } from './dialog-drawer-close/dialog-drawer-close.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { KebabToSpacePipe } from './pipes/kebab-to-space/kebab-to-space.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html/sanitize-html.pipe';
import { TimeTzPipe } from './pipes/time-tz/time-tz.pipe';
import { RouteTitleComponent } from './route-title/route-title.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { YnComponent } from './yn/yn.component';

const components = [
  DialogDrawerCloseComponent,
  GenericTableComponent,
  RouteTitleComponent,
  SnackBarComponent,
  YnComponent,
];
const pipes = [KebabToSpacePipe, SanitizeHtmlPipe, TimeTzPipe];

const modules = [
  ColorBlockModule,
  CommonModule,
  FormsModule,
  LoggerModule,
  MaterialModule,
  NgOptimizedImage,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  exports: [...components, ...modules, ...pipes],
})
export class SharedModule {}
