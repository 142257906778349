import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EngineRecord } from '@ci';
import { BaseFormDirective } from '../../../shared/base-form/base-form.directive';
import { LabEditComponentModel, labReset } from './lab-edit.component.model';

@Component({
  templateUrl: './lab-edit.component.html',
})
export class LabEditComponent extends BaseFormDirective<EngineRecord> implements OnInit {
  reset = false;
  readonly obj: EngineRecord = {};
  variables: string[] = [];

  private readonly data: LabEditComponentModel = inject(MAT_DIALOG_DATA);

  constructor() {
    super();
  }

  ngOnInit(): void {
    const {
      data: { action },
      obj,
    } = this;
    let row = this.data.row;

    this.reset = action === 'reset';
    this.variables = row.labs.map((l) => l.varName);

    if (this.reset) {
      const find = labReset.find((l) => l.id === row.id);
      if (find) {
        row = find;
      }
    }

    for (const lab of row.labs) {
      obj[lab.varName] = (lab.value ?? '').toString();
    }

    this.formCreate(obj);
  }
}
