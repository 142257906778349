import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
  AlgorithmModel,
  EngineClass,
  EngineRecord,
  EngineRunModel,
  EngineValue,
  NodeAny,
  NodeCond,
  NodeLabPanel,
  NodeStop,
  TreeClass,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subject } from 'rxjs';
import { OrderDetailsComponent } from '../../order/order-details/order-details.component';
import { OrderDetailsComponentModel } from '../../order/order-details/order-details.component.model';
import { EngineService } from '../../services/engine/engine.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { UtilsService } from '../../services/utils/utils.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { YnComponent } from '../../shared/yn/yn.component';
import { AlgorithmSimHistoryComponent } from '../algorithm-sim-history/algorithm-sim-history.component';
import { AlgorithmSimHistoryComponentModel } from '../algorithm-sim-history/algorithm-sim-history.component.model';

@Component({
  selector: 'hlds-algorithm-sim',
  templateUrl: './algorithm-sim.component.html',
  styleUrl: 'algorithm-sim.component.scss',
})
export class AlgorithmSimComponent extends BaseFormDirective<EngineRecord> implements OnInit {
  @Input({ required: true }) row$!: Observable<AlgorithmModel>;
  @Input({ required: true }) simHighlight$!: Subject<NodeAny[]>;
  @Input({ required: true }) simValues$!: Subject<Record<string, EngineValue> | null>;
  @Output() highlight = new EventEmitter<string>();

  engineRun: EngineRunModel = {
    engineNodes: [],
    error: '',
    orderVarNames: [],
    orders: [],
    stopLabels: [],
    stops: [],
  };
  highlightNode!: NodeCond | NodeLabPanel;
  highlightTestIds: string[] = [];
  missingNodes: NodeStop[] = [];
  tests: string[] = [];

  private readonly dialog = inject(MatDialog);
  private readonly engineService = inject(EngineService);
  private readonly simRun$ = new Subject<EngineRecord>();
  private readonly snackBar = inject(SnackBarService);
  private readonly translate = inject(TranslateService);
  private readonly utilsService = inject(UtilsService);

  constructor() {
    super({ nonNullable: true });
  }

  get sims() {
    return this._row.sims;
  }

  set sims(sims: EngineRecord[]) {
    this._row.sims = sims;
  }

  private _row!: AlgorithmModel;

  private get row() {
    return this._row;
  }

  private set row(row: AlgorithmModel) {
    const {
      tests,
      tree: { nodes },
    } = row;

    this._row = row;

    if (this.tests.length != tests.length || !this.tests.every((test) => tests.includes(test))) {
      this.tests = TreeClass.treeSortTests(nodes, tests);
      this.#initForm();
    }

    setTimeout(() => this.run(), 500);
  }

  history() {
    const { dialog, row, simRun$ } = this;
    const data: AlgorithmSimHistoryComponentModel = {
      data: row,
      run$: simRun$,
    };

    dialog
      .open<AlgorithmSimHistoryComponent, AlgorithmSimHistoryComponentModel>(
        AlgorithmSimHistoryComponent,
        {
          width: '1000px',
          maxWidth: '1000px',
          position: { bottom: '0px', left: '18rem' }, // TODO make prettier :(
          data,
          panelClass: 'transparent-dialog',
        },
      )
      .afterClosed()
      .subscribe(() => this.#calculateMissingNodes());
  }

  isNumber(value: EngineValue): boolean {
    return this.engineService.isNumber(value);
  }

  missing() {
    this.simHighlight$.next(this.missingNodes);
  }

  ngOnInit() {
    const { row$, destroyRef, simRun$ } = this;

    row$.pipe(takeUntilDestroyed(destroyRef)).subscribe((row: AlgorithmModel) => (this.row = row));

    simRun$.pipe(takeUntilDestroyed(destroyRef)).subscribe((values: EngineRecord) => {
      this.form.patchValue(values);
      this.run('history');
    });
  }

  run(state: 'clear' | 'history' | 'history-clear' | 'run' = 'run') {
    const {
      engineService,
      row: {
        tree: { nodes },
      },
      tests,
      simHighlight$,
      simValues$,
    } = this;
    const values = this.formValue;

    switch (state) {
      case 'history-clear':
        this.#historyClear();
        return;
      case 'clear':
        for (const test of tests) {
          values[test] = '';
        }

        this.form.patchValue(values);
        break;
    }

    simValues$.next(values);
    this.engineRun = engineService.run(nodes, values, tests);
    simHighlight$.next(this.engineRun.engineNodes);

    if (state !== 'history') {
      this.#historyAdd(values);
    }
  }

  runDetails() {
    const {
      dialog,
      row: {
        tree: { nodes },
      },
      engineRun,
    } = this;
    const data: OrderDetailsComponentModel = {
      nodes,
      engineRun,
    };

    this.engineService.setValues(nodes, this.formValue);

    dialog.open<OrderDetailsComponent, OrderDetailsComponentModel>(OrderDetailsComponent, {
      data,
      position: { bottom: '0px' },
      width: '800px',
      maxWidth: '800px',
    });
  }

  #calculateMissingNodes() {
    const {
      sims,
      row: {
        tree: { nodes },
      },
      tests,
      engineService,
    } = this;

    this.missingNodes = TreeClass.treeFind<NodeStop>(nodes, 'stop');

    for (const sim of sims) {
      engineService
        .run(nodes, sim, tests)
        .stops.forEach(
          (stop: NodeStop) =>
            (this.missingNodes = this.missingNodes.filter((n) => n.id !== stop.id)),
        );
    }
  }

  #historyAdd(values: EngineRecord) {
    const { sims, utilsService, tests, engineRun, engineService } = this;
    const reservedValues = Object.values(EngineClass.col);
    const resultCol = EngineClass.col.result;

    engineService.removeUnusedProperties(values, tests);
    delete values[resultCol];

    const index = sims.findIndex((s) => {
      const temp = JSON.parse(JSON.stringify(s));

      for (const v of reservedValues) {
        delete temp[v];
      }
      return utilsService.deepEqual(temp, values);
    });

    values[resultCol] = '';
    [engineRun.orderVarNames, engineRun.stopLabels].forEach((labels, index) => {
      if (labels.length) {
        if (values[resultCol]) {
          values[resultCol] += `; `;
        }

        if (index === 0) {
          values[resultCol] = `Orders: ${labels.join(', ')}`;
        } else {
          values[resultCol] += `Endpoints: ${labels.join(', ')}`;
        }
      }
    });

    if (index < 0) {
      sims.push(values);
    } else {
      sims[index][resultCol] = values[resultCol];
    }

    this.#calculateMissingNodes();
  }

  #historyClear() {
    const { dialog, sims, snackBar, translate } = this;
    const nonGold = sims.filter((f) => !f[EngineClass.col.star]);

    if (nonGold.length === 0) {
      snackBar.open({
        type: 'error',
        message: 'hlds.sim.message.all-star',
        translateMessage: true,
      });
    } else {
      dialog
        .open(YnComponent, {
          data: {
            title: 'hlds.sim.button.clear-history',
            titleTranslate: true,
            message: translate.instant('hlds.sim.message.clear-history', {
              count: nonGold.length,
            }),
            messageType: 'warn',
          },
        })
        .afterClosed()
        .pipe(filter((f) => !!f))
        .subscribe(() => (this.sims = sims.filter((f) => f[EngineClass.col.star])));
    }
  }

  #initForm() {
    const { tests, row, engineService } = this;
    const obj: EngineRecord = {};

    for (const test of tests) {
      obj[test] = '';
    }

    row.sims = row.sims.filter((row) => {
      const keys = Object.keys(row);
      return tests.every((test) => keys.includes(test));
    });

    engineService.removeUnusedProperties(row.sims, tests);
    this.formCreate(obj);
  }
}
