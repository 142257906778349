import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AboutComponent } from '../about/about.component';
import { routePath } from '../app-routing/app-routing';
import { localStorageKeys } from '../local-storage-keys';
import { I18nService } from '../services/i18n/i18n.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'hlds-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() showMenu = new EventEmitter<void>();

  readonly dialog = inject(MatDialog);
  readonly i18nService = inject(I18nService);
  readonly languages = this.i18nService.languages;
  readonly routeDefault = routePath.home;
  readonly themes: string[] = ['light-theme', 'dark-theme'];
  readonly translate = inject(TranslateService);
  readonly userService = inject(UserService);

  private readonly themeKey = localStorageKeys.theme;
  private _language = this.i18nService.ext;

  get language() {
    return this._language;
  }

  set language(ext: string) {
    const { translate, i18nService } = this;

    this._language = i18nService.ext = ext;
    localStorage.setItem(localStorageKeys.i18n, ext);
    translate.use(ext).subscribe();
  }

  private _theme!: string;

  get theme() {
    return this._theme;
  }

  set theme(theme: string) {
    if (this.theme !== theme) {
      this._theme = theme;
      localStorage.setItem(this.themeKey, theme);

      document.body.classList.remove(...this.themes);
      document.body.classList.add(theme);
    }
  }

  about() {
    this.dialog.open(AboutComponent, { width: '700px', maxWidth: '700px' });
  }

  ngOnInit() {
    const { themes } = this;
    const theme = localStorage.getItem(this.themeKey) || '';

    this.theme = themes.includes(theme) ? theme : themes[0];
  }
}
