import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { SnackBarData } from '../../shared/snack-bar/snack-bar.component.model';
import { SnackBarComponent } from '../../shared/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly snackBar = inject(MatSnackBar);
  private readonly translate = inject(TranslateService);

  /**
   * Opens a snack bar message to display to the user
   *
   * @param snackBarData the data the snack bar will use to display to the user
   */
  open(snackBarData: SnackBarData): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      duration: snackBarData.duration,
      panelClass: `mat--${snackBarData.type || 'success'}`,
      verticalPosition: 'top',
      data: snackBarData,
    });
  }

  error(e: HttpErrorResponse, id?: string): MatSnackBarRef<SnackBarComponent> {
    const { status } = e;
    let message = e.message;
    const messages: string[] = [];

    if ([HttpStatusCode.NotFound, HttpStatusCode.BadRequest].includes(status)) {
      if (id) {
        message = this.translate.instant('shared.error.not-found', { id });
      }
    } else if ([HttpStatusCode.UnprocessableEntity].includes(status)) {
      if (e.error?.message) {
        messages.push(e.error.message);
      }
    }

    return this.open({
      message,
      messages,
      type: 'error',
    });
  }
}
