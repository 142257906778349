<div [formGroup]="form" class="flex flex-col space-y-2 w-96">
  <mat-form-field>
    <mat-label>{{ 'hlds.settings.i18n.date' | translate }}</mat-label>
    <mat-select [formControl]="controls.date">
      <mat-option value="long">31-Dec-20</mat-option>
      <mat-option value="iso">2000-12-31</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.settings.i18n.language' | translate }}</mat-label>
    <mat-select [formControl]="controls.language">
      @for (language of languages; track language.ext) {
        <mat-option [value]="language.ext">{{ language.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
