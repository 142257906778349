import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderActionComponentModel } from './order-action.component.model';

@Component({
  templateUrl: './order-action.component.html',
})
export class OrderActionComponent {
  data: OrderActionComponentModel = inject(MAT_DIALOG_DATA);
}
