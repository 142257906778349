import { ITokenConfig, TokenType } from '@chevrotain/types';
import { createToken as ct, Lexer } from 'chevrotain';

export type CondLogicOperator = 'AND' | 'OR';
export const condLogicOperators: CondLogicOperator[] = ['AND', 'OR'];
export const condLogicOperator: (op: string) => CondLogicOperator = (op: string) =>
  ['||', 'OR'].includes(op.toLowerCase()) ? 'OR' : 'AND';
export const condOperators: string[] = ['<', '<=', '=', '!=', '>=', '>'];

export const condTokens: TokenType[] = [];
const createToken = (config: ITokenConfig): TokenType => condTokens[condTokens.push(ct(config)) - 1];

// DO NOT CHANGE THE ORDER OF THE creatToken(s)!!! ORDER is IMPORTANT!!!
const whiteSpace = createToken({
  name: 'whiteSpace',
  pattern: /\s+/,
  group: Lexer.SKIPPED,
});

const value = createToken({ name: 'value', pattern: Lexer.NA }); // meta
const valueString = createToken({
  name: 'valueString',
  pattern: /"(:?[^\\"]|\\(:?[bfnrtv"\\/]|u[\da-fA-F]{4}))*"/,
  categories: value,
});
const valueNumber = createToken({
  name: 'valueNumber',
  pattern: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/,
  categories: value,
});
const valueBoolean = createToken({
  name: 'valueBoolean',
  pattern: /true|false/i,
  categories: value,
});

// Allow dash/minus (-) for test variables - could be a problem??
const identifier = createToken({ name: 'identifier', pattern: /[a-z$]+[\w$-]*/i });
const parenLeft = createToken({ name: 'parenLeft', pattern: /\(/ });
const parenRight = createToken({ name: 'parenRight', pattern: /\)/ });

const compareOperator = createToken({ name: 'compareOperator', pattern: Lexer.NA }); // meta
const ne = createToken({ name: 'ne', pattern: /!=/, categories: compareOperator });
const ge = createToken({ name: 'ge', pattern: />=/, categories: compareOperator });
const le = createToken({ name: 'le', pattern: /<=/, categories: compareOperator });
const gt = createToken({ name: 'gt', pattern: />/, categories: compareOperator });
const lt = createToken({ name: 'lt', pattern: /</, categories: compareOperator });
const eqeq = createToken({ name: 'eqeq', pattern: /==/, categories: compareOperator });
const eq = createToken({ name: 'eq', pattern: /=/, categories: compareOperator });

const logicOperator = createToken({ name: 'logicOperator', pattern: Lexer.NA }); // meta
const logicAnd = createToken({ name: 'logicAnd', pattern: /&&/, categories: logicOperator });
const logicOr = createToken({ name: 'logicOr', pattern: /\|\|/, categories: logicOperator });

export type CondTokenName =
  | 'compareOperator'
  | 'eq'
  | 'eqeq'
  | 'ge'
  | 'gt'
  | 'identifier'
  | 'le'
  | 'logicAnd'
  | 'logicOperator'
  | 'logicOr'
  | 'lt'
  | 'ne'
  | 'parenLeft'
  | 'parenRight'
  | 'stringLiteral'
  | 'value'
  | 'valueBoolean'
  | 'valueNumber'
  | 'valueString'
  | 'whiteSpace';

export const condRec: Record<CondTokenName, TokenType> = {
  compareOperator: compareOperator,
  eq: eq,
  eqeq: eqeq,
  ge: ge,
  gt: gt,
  identifier: identifier,
  le: le,
  logicAnd: logicAnd,
  logicOperator: logicOperator,
  logicOr: logicOr,
  lt: lt,
  ne: ne,
  parenLeft: parenLeft,
  parenRight: parenRight,
  stringLiteral: valueString,
  value: value,
  valueBoolean: valueBoolean,
  valueNumber: valueNumber,
  valueString: valueString,
  whiteSpace: whiteSpace,
};
