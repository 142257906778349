<hlds-dialog-drawer-close
  [heading]="'hlds.order-action.title' | translate"
></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col">
  <div class="text-xl font-bold">
    @switch (data.row.type) {
      @case ('cond') {
        {{ data.row.cond }}
      }
      @default {
        {{ data.row.label }}
      }
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="true">
    {{ 'hlds.order-action.action.' + data.action | translate }}
  </button>
</mat-dialog-actions>
