import { LabsModel } from '@ci';
import { GenericTableAction } from '../../../shared/generic-table/generic-table.model';

export interface LabEditComponentModel {
  action: GenericTableAction;
  row: LabsModel;
}

export const labReset: LabsModel[] = [
  {
    id: 'L1',
    labs: [
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-C-mix',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-S-mix',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C-mix',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-S-mix',
      },
    ],
    patientId: 'alpha',
  },
  {
    id: 'L2',
    labs: [
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-C-mix',
      },
      {
        createdAt: '2024-03-01 08:00',
        updatedAt: '2024-03-01 08:10',
        value: '1.17',
        varName: 'SCT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-S-mix',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C-mix',
      },
      {
        createdAt: '2024-03-01 08:20',
        updatedAt: '2024-03-01 08:30',
        value: '1.21',
        varName: 'dRVVT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-S-mix',
      },
    ],
    patientId: 'bravo',
  },
  {
    id: 'L3',
    labs: [
      {
        createdAt: '2024-03-01 08:40',
        updatedAt: '2024-03-01 08:50',
        value: '1.16',
        varName: 'SCT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'SCT-C-mix',
      },
      {
        createdAt: '2024-03-01 09:00',
        updatedAt: '2024-03-01 09:10',
        value: '1.17',
        varName: 'SCT-S',
      },
      {
        createdAt: '2024-03-01 09:15',
        updatedAt: '2024-03-01 09:18',
        value: '1.15',
        varName: 'SCT-S-mix',
      },
      {
        createdAt: '2024-03-01 09:20',
        updatedAt: '2024-03-01 09:30',
        value: '1.12',
        varName: 'dRVVT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C-mix',
      },
      {
        createdAt: '2024-03-01 09:30',
        updatedAt: '2024-03-01 09:40',
        value: '1.21',
        varName: 'dRVVT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-S-mix',
      },
    ],
    patientId: 'charlie',
  },
  {
    id: 'L4',
    labs: [
      {
        createdAt: '2024-03-01 10:00',
        updatedAt: '2024-03-01 10:10',
        value: '1.16',
        varName: 'SCT-C',
      },
      {
        createdAt: '2024-03-01 10:20',
        updatedAt: '2024-03-01 10:30',
        value: '1.17',
        varName: 'SCT-C-mix',
      },
      {
        createdAt: '2024-03-01 10:40',
        updatedAt: '2024-03-01 10:50',
        value: '1.17',
        varName: 'SCT-S',
      },
      {
        createdAt: '2024-03-01 11:00',
        updatedAt: '2024-03-01 11:10',
        value: '1.21',
        varName: 'SCT-S-mix',
      },
      {
        createdAt: '2024-03-01 11:20',
        updatedAt: '2024-03-01 11:30',
        value: '1.12',
        varName: 'dRVVT-C',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-C-mix',
      },
      {
        createdAt: '2024-03-01 11:40',
        updatedAt: '2024-03-01 11:50',
        value: '1.21',
        varName: 'dRVVT-S',
      },
      {
        createdAt: '',
        updatedAt: '',
        value: '',
        varName: 'dRVVT-S-mix',
      },
    ],
    patientId: 'delta',
  },
  {
    id: 'L5',
    labs: [
      {
        createdAt: '2024-03-01 12:00',
        updatedAt: '2024-03-01 12:10',
        value: '1.16',
        varName: 'SCT-C',
      },
      {
        createdAt: '2024-03-01 12:20',
        updatedAt: '2024-03-01 12:30',
        value: '1.17',
        varName: 'SCT-C-mix',
      },
      {
        createdAt: '2024-03-01 12:40',
        updatedAt: '2024-03-01 12:50',
        value: '1.17',
        varName: 'SCT-S',
      },
      {
        createdAt: '2024-03-01 13:00',
        updatedAt: '2024-03-01 13:10',
        value: '1.21',
        varName: 'SCT-S-mix',
      },
      {
        createdAt: '2024-03-01 13:20',
        updatedAt: '2024-03-01 13:30',
        value: '1.12',
        varName: 'dRVVT-C',
      },
      {
        createdAt: '2024-03-01 14:00',
        updatedAt: '2024-03-01 14:10',
        value: '1.0',
        varName: 'dRVVT-C-mix',
      },
      {
        createdAt: '2024-03-01 14:20',
        updatedAt: '2024-03-01 14:30',
        value: '1.21',
        varName: 'dRVVT-S',
      },
      {
        createdAt: '2024-03-01 14:40',
        updatedAt: '2024-03-01 14:50',
        value: '1.1',
        varName: 'dRVVT-S-mix',
      },
    ],
    patientId: 'echo',
  },
  {
    id: 'L_AX1',
    labs: [
      {
        createdAt: '2024-03-01 12:00',
        updatedAt: '2024-03-01 12:10',
        value: '',
        varName: 'APTT-Mix',
      },
      {
        createdAt: '2024-03-01 12:20',
        updatedAt: '2024-03-01 12:30',
        value: '',
        varName: 'APTT-sec',
      },
      {
        createdAt: '2024-03-01 12:40',
        updatedAt: '2024-03-01 12:50',
        value: '',
        varName: 'Incubated_APTT_Mix_Result',
      },
      {
        createdAt: '2024-03-01 13:00',
        updatedAt: '2024-03-01 13:10',
        value: '',
        varName: 'LFI-Flag',
      },
      {
        createdAt: '2024-03-01 13:20',
        updatedAt: '2024-03-01 13:30',
        value: '',
        varName: 'QFA',
      },
      {
        createdAt: '2024-03-01 14:00',
        updatedAt: '2024-03-01 14:10',
        value: '',
        varName: 'TT5',
      },
    ],
    patientId: 'fox',
  },
  {
    id: 'L_AX2',
    labs: [
      {
        createdAt: '2024-03-01 12:00',
        updatedAt: '2024-03-01 12:10',
        value: '',
        varName: 'APTT-Mix',
      },
      {
        createdAt: '2024-03-01 12:20',
        updatedAt: '2024-03-01 12:30',
        value: '36.1',
        varName: 'APTT-sec',
      },
      {
        createdAt: '2024-03-01 12:40',
        updatedAt: '2024-03-01 12:50',
        value: '',
        varName: 'Incubated_APTT_Mix_Result',
      },
      {
        createdAt: '2024-03-01 13:00',
        updatedAt: '2024-03-01 13:10',
        value: '',
        varName: 'LFI-Flag',
      },
      {
        createdAt: '2024-03-01 13:20',
        updatedAt: '2024-03-01 13:30',
        value: '',
        varName: 'QFA',
      },
      {
        createdAt: '2024-03-01 14:00',
        updatedAt: '2024-03-01 14:10',
        value: '',
        varName: 'TT5',
      },
    ],
    patientId: 'fox',
  },
  {
    id: 'L_AX3',
    labs: [
      {
        createdAt: '2024-03-01 12:00',
        updatedAt: '2024-03-01 12:10',
        value: '',
        varName: 'APTT-Mix',
      },
      {
        createdAt: '2024-03-01 12:20',
        updatedAt: '2024-03-01 12:30',
        value: '36.1',
        varName: 'APTT-sec',
      },
      {
        createdAt: '2024-03-01 12:40',
        updatedAt: '2024-03-01 12:50',
        value: '',
        varName: 'Incubated_APTT_Mix_Result',
      },
      {
        createdAt: '2024-03-01 13:00',
        updatedAt: '2024-03-01 13:10',
        value: '0',
        varName: 'LFI-Flag',
      },
      {
        createdAt: '2024-03-01 13:20',
        updatedAt: '2024-03-01 13:30',
        value: '99.9',
        varName: 'QFA',
      },
      {
        createdAt: '2024-03-01 14:00',
        updatedAt: '2024-03-01 14:10',
        value: '16.9',
        varName: 'TT5',
      },
    ],
    patientId: 'fox',
  },
  {
    id: 'L_AX4',
    labs: [
      {
        createdAt: '2024-03-01 12:00',
        updatedAt: '2024-03-01 12:10',
        value: '35.9',
        varName: 'APTT-Mix',
      },
      {
        createdAt: '2024-03-01 12:20',
        updatedAt: '2024-03-01 12:30',
        value: '36.1',
        varName: 'APTT-sec',
      },
      {
        createdAt: '2024-03-01 12:40',
        updatedAt: '2024-03-01 12:50',
        value: '',
        varName: 'Incubated_APTT_Mix_Result',
      },
      {
        createdAt: '2024-03-01 13:00',
        updatedAt: '2024-03-01 13:10',
        value: '0',
        varName: 'LFI-Flag',
      },
      {
        createdAt: '2024-03-01 13:20',
        updatedAt: '2024-03-01 13:30',
        value: '99.9',
        varName: 'QFA',
      },
      {
        createdAt: '2024-03-01 14:00',
        updatedAt: '2024-03-01 14:10',
        value: '16.9',
        varName: 'TT5',
      },
    ],
    patientId: 'fox',
  },
];
