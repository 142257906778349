import { Injectable } from '@angular/core';
import { NodeAny, TestModelCount, TreeClass } from '@ci';
import { AlgorithmTreeEditComponentRes } from '../../algorithm/algorithm-tree-edit/algorithm-tree-edit.component.model';

@Injectable({
  providedIn: 'root',
})
export class TreeService extends TreeClass {
  nodesInvalid(nodes: NodeAny[], matches: NodeAny[]) {
    TreeClass.treeSafe(nodes);

    for (const node of nodes) {
      switch (node.type) {
        case 'todo':
          matches.push(node);
          break;
        case 'stop':
        case 'comment':
          if (node.label.trim().length == 0) {
            matches.push(node);
          }
          break;
        case 'cond':
          if (this.conditionValidator(node.varNames, node.cond)) {
            matches.push(node);
          }
          break;
        case 'lab_panel':
          if (node.varNames.length === 0) {
            matches.push(node);
          }
          break;
      }
    }
  }

  nodesRootsInsert(rows: AlgorithmTreeEditComponentRes[], nodes: NodeAny[]) {
    const { rootStartId } = TreeClass;
    const roots = TreeClass.treeRoots(nodes);
    const tmp = nodes.filter((node) => !roots.find((root) => root === node));
    const newRows: NodeAny[] = [];

    // Give some space
    roots.forEach((node, index) => (node.id = rootStartId + index * 5)); // Give some space

    for (const { lr, index } of rows) {
      const { id } = roots[index];
      newRows.push(TreeClass.emptyCond(lr === 'left' ? id - 1 : id + 1));
    }

    roots.push(...newRows);
    roots.sort((a, b) => a.id - b.id).forEach((node, index) => (node.id = rootStartId + index)); // put stuff back

    nodes.length = 0;
    nodes.push(...tmp, ...roots);
  }

  /**
   * Navigate the tree and keep a count a test is each of the conditional nodes. Since a user can
   * delete a test, remove the test for any node that was using the test.
   *
   * @param nodes list af all nodes
   * @param tests list of all the tests
   */
  nodesTestCounts(nodes: NodeAny[], tests: TestModelCount[]) {
    for (const test of tests) {
      test.count = 0;
    }

    for (const node of nodes) {
      if (node.type === 'cond' || node.type === 'lab_panel') {
        // If the user deleted a test, remove the test from node.varNames
        node.varNames = node.varNames.filter((id) => tests.find((t) => t.varName === id));
        tests
          .filter((test) => node.varNames.includes(test.varName))
          .forEach((match) => ++match.count);
      }
    }
  }
}
