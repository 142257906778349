import { Component, Input } from '@angular/core';

@Component({
  selector: 'hlds-route-title',
  templateUrl: './route-title.component.html',
})
export class RouteTitleComponent {
  @Input({ required: true }) title!: string;
  @Input() route!: string;
}
