import {
  InterpModel,
  NodeCustom,
  NodeShapeType,
  SettingsDispositionModel,
  TestCustomModel,
} from '@ci';

export interface SettingsAlgorithmLabel {
  falseLabel: string;
  trueLabel: string;
}

export interface SettingsAlgorithmsModel {
  auto: {
    interpretation: boolean;
    reflex: boolean;
  };
  dispositions: SettingsDispositionModel[];
  labels: SettingsAlgorithmLabel;
  interps: InterpModel[];
  shapes: Record<NodeShapeType, NodeCustom>;
  shapesDefault: Record<NodeShapeType, NodeCustom>;
  testCustoms: TestCustomModel[];
}

export type SettingsDateFormat = 'iso' | 'long';
export const settingsDateFormats: SettingsDateFormat[] = ['iso', 'long'];

export interface SettingsI18nModel {
  date: SettingsDateFormat;
  language: string;
}
