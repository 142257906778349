import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './hh.component.html',
})
export class HhComponent {
  readonly orders = ['1', '2', '3', '4', '5'];
  sanitizeUrl!: SafeResourceUrl;

  private readonly sanitizer = inject(DomSanitizer);

  iframe(orderNumber: string) {
    this.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      location.href.replace(/\/hh.*/, `/orders/${orderNumber};iframe=1`),
    );
  }
}
