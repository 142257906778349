import { BaseModelDate, BaseModelId } from './base.model';
import { PatientModel } from './patient.model';

export type LabValue = number | string;

export interface LabModel extends BaseModelDate {
  varName: string;
  value: LabValue;
}

export interface LabsModel extends BaseModelId {
  labs: LabModel[];
  patient?: PatientModel;
  patientId: string;
}
