import { Component, inject, OnDestroy } from '@angular/core';
import { SettingsService } from '../services/settings/settings.service';

@Component({
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnDestroy {
  private readonly settingsService = inject(SettingsService);

  ngOnDestroy() {
    this.settingsService.update();
  }
}
