<hlds-dialog-drawer-close [heading]="'Lab Edit'"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col items-end" [formGroup]="form">
  @for (variable of variables; track variable) {
    <mat-form-field class="smaller-font">
      <mat-label>{{ variable }}</mat-label>
      <input matInput [formControl]="controls[variable]" />
    </mat-form-field>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  @if (reset) {
    <button mat-button [mat-dialog-close]="formValue">Reset!!!!</button>
  } @else {
    <button mat-flat-button [mat-dialog-close]="formValue">
      {{ 'shared.button.save' | translate }}
    </button>
  }
</mat-dialog-actions>
