export type TestUnitType = 'string' | 'number' | 'boolean';
export const testUnitTypes: TestUnitType[] = ['number', 'boolean', 'string'];

export interface TestModel {
  category: string;
  description: string;
  tags?: string[];
  unit: string;
  unitType: TestUnitType;
  varName: string /** unique */;
  varNameParent?: string;
}

export interface TestCustomModel extends TestModel {
  query: string;
  options?: string[];
}

export interface TestModelCount extends TestModel {
  count: number;
}
