import { AlgorithmModel } from './algorithm.model';
import { BaseModel, BaseModelDate } from './base.model';
import { LabsModel } from './labs.model';

export type OrderAction = 'cancel' | 'continue' | 'pause';

export type OrderNodeActiveStatus =
  | 'node_active_running'
  | 'node_active_waiting_to_run'
  | 'node_active_comment_approved';
export type OrderNodePausedStatus =
  | 'node_paused_by_user'
  | 'node_paused_comment_approval'
  | 'node_paused_decision_approval'
  | 'node_paused_decision_question';
export type OrderNodeDoneStatus =
  | 'node_done_auto'
  | 'node_done_comment_approved'
  | 'node_done_decision_approved'
  | 'node_done_decision_question_answered';
export type OrderNodeCancelStatus = 'node_cancelled_by_user' | 'node_cancelled_by_system';
export type OrderNodeStatus =
  | OrderNodeActiveStatus
  | OrderNodePausedStatus
  | OrderNodeCancelStatus
  | OrderNodeDoneStatus;
export type OrderNodeState = 'node_active' | 'node_paused' | 'node_cancelled' | 'node_done';

export type OrderState = 'order_not_started' | 'order_in_progress' | 'order_paused' | 'order_done';
export type OrderDoneStatus = 'order_done_done' | 'order_done_cancelled' | 'order_done_wait_interp';
export type OrderInProgressStatus = 'order_in_progress_progress' | 'order_in_progress_partial';
export type OrderStatus = OrderDoneStatus | OrderInProgressStatus;

export interface OrderNodeStateModel extends Pick<BaseModelDate, 'createdAt'> {
  nodeId: number;
  nodeLabel?: string;
  nodeState: OrderNodeState;
  nodeStatus: OrderNodeStatus;
}

export interface OrderNodeNote extends BaseModelDate {
  nodeLabel?: string;
  nodeId: number;
  note: string;
}

export interface OrderModelJoin {
  algorithm: AlgorithmModel;
  labs: Required<LabsModel>;
}

export interface OrderModel extends BaseModel, Partial<OrderModelJoin> {
  algorithmId: string;
  disposition: string;
  labsId: string;
  nodeNotes: OrderNodeNote[];
  nodeStates: OrderNodeStateModel[];
  orderState: OrderState;
  orderStatus: OrderStatus;
}
