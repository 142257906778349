<div class="flex flex-col">
  @if (data.message) {
    <div>
      @if (data.translateMessage) {
        {{ data.message | translate }}
      } @else {
        {{ data.message }}
      }
    </div>
  }
  @if (data.messages?.length) {
    <div>
      @for (message of data.messages; track message) {
        <div>
          @if (data.translateMessages) {
            {{ message | translate }}
          } @else {
            {{ message }}
          }
        </div>
      }
    </div>
  }
  @if (data.action) {
    <div class="flex flex-row justify-start items-center">
      <button mat-raised-button (click)="data.action()">
        {{ data.actionTitle || 'Confirm' }}
      </button>
    </div>
  }
</div>
