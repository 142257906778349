import { Directive, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../services/utils/utils.service';
import { BaseSubscriptionsDirective } from '../base-subscriptions/base-subscriptions.directive';

import { BaseFormSuper } from './base-form.model';

/**
 * Not an Angular Component, but a base class for safe form building
 */
@Directive()
export class BaseFormDirective<
  FormModel extends object,
  FormValue = FormModel,
> extends BaseSubscriptionsDirective {
  readonly fb = inject(FormBuilder);
  form!: FormGroup;

  private _resetValue!: FormValue;
  private readonly utils = inject(UtilsService);

  constructor(public bf: Partial<BaseFormSuper> = {}) {
    super();
  }

  /**
   * @return typesafe form control
   */
  get controls(): Record<keyof FormModel, FormControl> {
    return this.form.controls as Record<keyof FormModel, FormControl>;
  }

  get controlsArray(): Record<keyof FormModel, FormArray> {
    return this.form.controls as Record<keyof FormModel, FormArray>;
  }

  /**
   * return true if resetValue === formValue
   */
  get formUnchanged(): boolean {
    return this.utils.deepEqual<FormValue>(this.formValue, this.resetValue);
  }

  /**
   * @return typesafe form.value, which will contain all the control values
   */
  get formValue(): FormValue {
    return this.form.value as FormValue;
  }

  /**
   * @return like formValue() but will include disabled values
   */
  get rawValue(): FormValue {
    return this.form.getRawValue() as FormValue;
  }

  get resetValue(): FormValue {
    return this._resetValue;
  }

  set resetValue(value: FormValue) {
    this._resetValue = value;
    this.form.markAsPristine();
  }

  /**
   * typesafe form creation
   */
  formCreate(model: FormModel): void {
    const { fb, bf } = this;
    const { nonNullable, allRequired } = bf;

    this.form = nonNullable ? fb.nonNullable.group(model) : fb.group(model);
    this.resetValue = this.formValue;

    if (allRequired) {
      const { controls } = this.form;

      Object.keys(controls).forEach((key: string) => {
        controls[key].addValidators(Validators.required);
      });
    }
  }

  formReset(): void {
    if (this.resetValue) {
      this.form.patchValue(this.resetValue);
    }
    this.form.markAsPristine();
  }

  patchValue(
    value: Partial<FormModel>,
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ): void {
    this.form.patchValue(value, options);
  }
}
