import { inject, Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

/**
 * Wrapper around localStorage
 */
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly utils = inject(UtilsService);

  get(key: string, defaultValue?: string): string | null {
    let result: string | null = localStorage.getItem(key);

    if (!result && defaultValue) {
      result = defaultValue;
      localStorage.setItem(key, defaultValue);
    }

    return result;
  }

  getObject<T extends object>(key: string): T | null {
    const item: string | null = localStorage.getItem(key);
    let result: T | null = null;

    if (item) {
      try {
        result = JSON.parse(item); // try/catch for crappy data
      } catch (e) {} // eslint-disable-line no-empty
    }

    return result;
  }

  getSafeObject<T extends object>(key: string, defaultValue: T): T {
    let result: T | null = this.getObject<T>(key);

    if (result) {
      const withMissing: T = Object.assign({}, defaultValue, result);

      if (!this.utils.deepEqual<T>(result, withMissing)) {
        result = withMissing;
        this.setObject(key, result); // write the object back if some fields are missing
      }
    } else {
      result = defaultValue;
      this.setObject(key, defaultValue);
    }

    return result;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  setObject<T extends object>(key: string, value: T | null): void {
    if (value) {
      this.set(key, JSON.stringify(value));
    }
  }
}
