/** Most b/e data structures have this as the base */
export interface BaseModelId {
  id: string;
}

export interface BaseModelDate {
  createdAt: string;
  updatedAt: string;
}

export interface BaseModel extends BaseModelDate, BaseModelId {}
