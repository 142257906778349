import { inject, Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  SnapshotAction,
} from '@angular/fire/compat/database';
import { LabsModel } from '@ci';
import { map, Observable, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LabService {
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef: AngularFireList<LabsModel> = this.fireDb.list('/labs');
  private _rows!: LabsModel[];

  static get emptyLabs(): LabsModel {
    return {
      id: '',
      labs: [],
      patientId: '',
    };
  }

  get rows(): LabsModel[] {
    return this._rows;
  }

  getCollection(): Observable<LabsModel[]> {
    const { fireRef } = this;

    if (this.rows) {
      return of(this.rows);
    }

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<LabsModel>[]) =>
        actions.map(
          (action: SnapshotAction<LabsModel>) =>
            ({
              id: action.payload.key,
              ...action.payload.val(),
            }) as LabsModel,
        ),
      ),
      tap((rows) => {
        this._rows = rows;
        for (const row of rows) {
          row.labs.sort((a, b) => a.varName.localeCompare(b.varName));
        }
      }),
      take(1),
    );
  }

  update(lab: LabsModel) {
    this.fireRef.set(lab.id, lab).then();
  }
}
