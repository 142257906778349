import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YnComponentModel } from './yn.component.model';

@Component({
  templateUrl: './yn.component.html',
  styleUrl: './yn.component.scss',
})
export class YnComponent implements OnInit {
  message!: string;
  messageType!: string;
  title!: string;

  private readonly data: YnComponentModel = inject(MAT_DIALOG_DATA);
  private readonly translate = inject(TranslateService);

  ngOnInit() {
    const {
      translate,
      data: { titleTranslate, title, messageTranslate, message, messageType },
    } = this;

    this.title = titleTranslate ? translate.instant(title) : title;
    this.message = messageTranslate ? translate.instant(message) : message;
    this.messageType = messageType || '';
  }
}
