import { inject, Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../../services/utils/utils.service';

@Pipe({
  name: 'kebabToSpace',
})
export class KebabToSpacePipe implements PipeTransform {
  private readonly utilsService = inject(UtilsService);

  /**
   * @param value now-is-the-time
   * @return now is the time
   */
  transform(value: string): string {
    return this.utilsService.kebabToSpace(value);
  }
}
