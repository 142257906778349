import { CondEval } from '../cond/cond-eval';
import {
  EngineNode,
  EngineNodeCond,
  EngineNodeLabPanel,
  EngineRunModel,
  EngineValue,
  NodeAny,
  NodeCond,
  NodeLabPanel,
  NodeStop,
} from '../models';
import { TreeClass } from './tree-class';

export class EngineClass {
  static readonly col: Record<'result' | 'star', string> = {
    result: 'Result',
    star: 'Star',
  };

  isNumber(value: unknown): boolean {
    return Number.isFinite(value);
  }

  isValue(value: unknown): boolean {
    let result = false;

    switch (typeof value) {
      case 'string':
        result = value.trim().length > 0;
        break;
      case 'number':
        result = this.isNumber(value);
        break;
      case 'boolean':
        result = true;
        break;
    }

    return result;
  }

  removeUnusedProperties(values: Record<string, EngineValue>[] | Record<string, EngineValue>, varNames: string[]) {
    if (varNames.length === 0) {
      return;
    }

    if (Array.isArray(values)) {
      for (const value of values) {
        this.removeUnusedProperties(value, varNames);
      }
    } else {
      const reservedValues = Object.values(EngineClass.col);

      Object.keys(values)
        .filter((key) => !reservedValues.includes(key))
        .forEach((key) => {
          if (!varNames.includes(key)) {
            delete values[key];
          }
        });
    }
  }

  run(nodes: NodeAny[], values: Record<string, EngineValue>, varNames: string[]): EngineRunModel {
    const engineNodes: EngineNode[] = [];
    const roots: NodeCond[] = TreeClass.treeFind<NodeCond>(TreeClass.treeRoots(nodes), 'cond');
    let error = '';

    this.setValues(nodes, values);

    try {
      for (const root of roots) {
        this.#run(nodes, root, engineNodes);
      }
    } catch (e) {
      error = (e as unknown as any).toString(); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    this.removeUnusedProperties(values, varNames);

    const orders = engineNodes
      .filter((node): node is EngineNodeLabPanel | EngineNodeCond => node.type === 'cond' || node.type === 'lab_panel')
      .filter((node) => node.testValues.some((v) => !v));
    const stops = TreeClass.treeFind<NodeStop>(engineNodes, 'stop');
    const ordersSet = new Set<string>();
    const stopsSet = new Set<string>(stops.map((stop) => stop.label).filter((v) => !!v));

    orders.forEach((order) => {
      order.testValues.forEach((test, index) => {
        if (!test) {
          ordersSet.add(order.varNames[index]);
        }
      });
    });

    return {
      engineNodes,
      error,
      orders,
      orderVarNames: Array.from(ordersSet),
      stops,
      stopLabels: Array.from(stopsSet),
    };
  }

  #run(nodes: NodeAny[], cond: NodeCond, stats: NodeAny[]) {
    const res = new CondEval(cond).run();
    const impossibleId = -999;

    stats.push(cond);

    if (typeof res === 'boolean') {
      const edge = cond.edges.find((edge) => edge.index === (res ? 1 : 0));

      if (edge) {
        const node = TreeClass.nodeFind(nodes, edge.id);

        if (node) {
          if (node.type !== 'cond') {
            stats.push(node);
          }

          switch (node.type) {
            case 'cond':
              this.#run(nodes, node, stats);
              break;
            case 'lab_panel':
            case 'comment':
              let nextId = node.edges.length > 0 ? node.edges[0].id : impossibleId;
              let next = TreeClass.nodeFind(nodes, nextId);

              while (next) {
                if (next.type !== 'cond') {
                  stats.push(next);
                }

                if (next.type === 'cond') {
                  this.#run(nodes, next, stats);
                  break;
                } else if (next.type === 'lab_panel' || next.type === 'comment') {
                  nextId = next.edges.length > 0 ? next.edges[0].id : impossibleId;
                  next = TreeClass.nodeFind(nodes, nextId);
                } else {
                  break;
                }
              }
              break;
          }
        }
      }
    }
  }

  /**
   * Navigate the tree and set the values in each of the condition modes with the values in "rec"
   *
   * @param nodes all nodes in the tree
   * @param values record of inputs
   */
  setValues(nodes: NodeAny[], values: Record<string, EngineValue>) {
    for (const key of Object.keys(values)) {
      const keyLow = key.toLowerCase();

      if (keyLow !== key) {
        values[keyLow] = values[key];
      }
      switch (typeof values[keyLow]) {
        case 'number':
        case 'boolean':
          values[keyLow] = values[keyLow].toString();
          break;
        case 'string':
          break;
        default:
          values[keyLow] = '';
      }
    }

    nodes
      .filter((node): node is NodeCond | NodeLabPanel => node.type === 'cond' || node.type === 'lab_panel')
      .forEach((node) => {
        node.testValues = node.varNames.map((varName) => {
          const value = values[varName.toLowerCase()];

          if (node.type === 'cond') {
            node.condIsTrue = false;
          }

          if (typeof value !== 'string') {
            if (node.type === 'cond') {
              throw new Error(`Bad condition with ${varName}\n${node.cond}\n${JSON.stringify(values, null, 2)}`);
            } else {
              throw new Error(`Bad lab panel with ${varName}\n${JSON.stringify(values, null, 2)}`);
            }
          }

          return value;
        });
      });
  }

  testValuesComplete(node: EngineNodeCond | EngineNodeLabPanel): boolean {
    return node.testValues.every((value) => this.isValue(value));
  }

  toString(value: unknown): string {
    let result = '';

    switch (typeof value) {
      case 'string':
        result = value.trim();
        break;
      case 'number':
        result = this.isNumber(value) ? value.toString() : '';
        break;
      case 'boolean':
        result = value.toString();
        break;
    }

    return result;
  }
}
