import { inject, Injectable } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user!: UserModel;

  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(SnackBarService);

  constructor() {
    this.http.get<UserModel>('assets/mock/user.json').subscribe({
      next: (resp: UserModel) => (this.user = resp),
      error: (e) => this.snackBar.error(e),
    });
  }
}
