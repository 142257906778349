import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';

// Modern Angular Fire imports
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { INGXLoggerConfig, LoggerModule } from 'ngx-logger';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './shared/error-handler/error-handler';

/**Configure the translation files. */
const translateProvider: Provider = {
  provide: TranslateLoader,
  useFactory: (http: HttpBackend) =>
    new MultiTranslateHttpLoader(http, [
      { prefix: './assets/translate/hlds.', suffix: '.json' },
      { prefix: './assets/translate/shared.', suffix: '.json' },
    ]),
  deps: [HttpBackend],
};

const loggerConfig: INGXLoggerConfig = {
  enableSourceMaps: !environment.production,
  level: environment.ngxLoggerLevel,
  disableConsoleLogging: false,
  colorScheme: ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    LoggerModule.forRoot(loggerConfig),
    TranslateModule.forRoot({
      loader: translateProvider,
      defaultLanguage: 'en', // English is default. i18n.json will define the other languages supported
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
