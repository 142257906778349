<mat-toolbar>
  <div class="flex flex-row justify-start items-center w-full pt-2 pb-2">
    <div class="flex-[25%]">
      <button
        mat-icon-button
        (click)="showMenu.emit()"
        [matTooltip]="'hlds.about.main' | translate"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div
      class="flex flex-row justify-center items-center gap-x-2 cursor-pointer flex-1"
      [routerLink]="['/', routeDefault]"
    >
      <div
        [matTooltip]="'hlds.about.app-name-long' | translate"
        class="flex flex-row gap-x-2 items-center"
      >
        <img
          alt="HLDS Logo"
          ngSrc="./assets/img/hlds.svg"
          priority
          width="24"
          height="24"
          class="h-[24px]"
        />
        <div>{{ 'hlds.about.app-name' | translate }}</div>
      </div>
    </div>
    <div class="flex flex-row justify-end items-center cursor-pointer flex-[25%]">
      @if (userService.user) {
        <div class="circle mr-1" [matTooltip]="userService.user.fullName">
          {{ userService.user.initials }}
        </div>
      }
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [matMenuTriggerFor]="languages_">
      <mat-icon>language</mat-icon>
      <span>{{ 'shared.i18n.menu-item' | translate }}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="theme_">
      <mat-icon>format_color_fill</mat-icon>
      <span>{{ 'shared.theme.title' | translate }}</span>
    </button>
    <button mat-menu-item (click)="about()">
      <mat-icon>info_outline</mat-icon>
      <span>{{ 'hlds.about.title' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
<mat-menu #languages_="matMenu">
  @for (l of languages; track l.ext) {
    <button mat-menu-item (click)="language = l.ext">
      <mat-icon [ngClass]="{ invisible: l.ext !== language }">check</mat-icon
      ><span>{{ l.label }}</span>
    </button>
  }
</mat-menu>
<mat-menu #theme_="matMenu">
  @for (t of themes; track t) {
    <button mat-menu-item (click)="theme = t">
      <mat-icon [ngClass]="{ invisible: theme !== t }">check</mat-icon
      ><span>{{ 'shared.theme.theme.' + t | translate }}</span>
    </button>
  }
</mat-menu>
