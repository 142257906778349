@if (iframe) {
  <hlds-generic-table
    [items]="orderItems"
    [rows]="orders"
    [options]="optionsOrder"
    (run)="actionOrder($event)"
  ></hlds-generic-table>
} @else {
  <div class="router-outlet">
    <hlds-route-title [title]="'hlds.order-list.title' | translate"></hlds-route-title>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
      @for (item of tabs; track item) {
        <a mat-tab-link (click)="tabActive = item" [active]="tabActive === item">{{
          item | titlecase
        }}</a>
      }
    </nav>
    @if (orders) {
      <div class="flex-1 overflow-clip mt-3">
        @switch (tabActive) {
          @case ('orders') {
            <hlds-generic-table
              [items]="orderItems"
              [rows]="orders"
              [options]="optionsOrder"
              (run)="actionOrder($event)"
            ></hlds-generic-table>
          }
          @case ('patients') {
            <hlds-generic-table [items]="patientItems" [rows]="patients"></hlds-generic-table>
          }
          @case ('labs') {
            <hlds-generic-table
              [items]="labItems"
              [rows]="labs"
              [options]="optionLabs"
              (run)="actionLab($event)"
            ></hlds-generic-table>
          }
        }
      </div>
    }
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  </div>
}
<ng-template #labsTemplate let-row="row" let-item="item">
  @switch (item.key) {
    @case ('name') {
      {{ row.patient.name }}
    }
    @case ('labs') {
      <table mat-table [dataSource]="rowLabs(row)">
        <ng-container matColumnDef="varName">
          <th mat-header-cell *matHeaderCellDef>Test</th>
          <td mat-cell *matCellDef="let row">{{ row.varName }}</td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let row">{{ row.value }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['varName', 'value']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['varName', 'value']"></tr>
      </table>
    }
  }
</ng-template>
<ng-template #ordersTemplate let-row="row" let-item="item">
  @switch (item.key) {
    @case ('algorithmName') {
      {{ rowOrder(row).algorithm?.name }}
    }
    @case ('patientName') {
      {{ rowOrder(row).labs?.patient?.name }}
    }
    @case ('orderState') {
      <span>{{
        'hlds.order.state.' + (rowOrder(row).orderState || rowOrder(row).orderState) | translate
      }}</span>
    }
  }
</ng-template>
