<div class="node-edit-cond flex flex-col">
  @switch (parent.controls.type.value) {
    @case ('cond') {
      <ng-container *ngTemplateOutlet="cond_"></ng-container>
    }
    @case ('lab_panel') {
      <ng-container *ngTemplateOutlet="labPanel_"></ng-container>
    }
  }
</div>

<ng-template #labPanel_>
  <ng-container *ngTemplateOutlet="addTest_"></ng-container>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.lab_panel.label' | translate }}</mat-label>
    <input [formControl]="parent.controls.label" matInput required />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.lab_panel.description' | translate }}</mat-label>
    <textarea
      rows="3"
      [formControl]="parent.controls.description"
      matInput
      [cdkTextareaAutosize]="true"
    ></textarea>
  </mat-form-field>
  <div class="mb-4">
    <div>{{ 'hlds.node-edit.type.cond.autoReflex-heading' | translate }}</div>
    <mat-checkbox [formControl]="parent.controls.autoReflex"
      >{{ 'hlds.settings.algorithms.autoReflex' | translate }}
    </mat-checkbox>
  </div>
</ng-template>

<ng-template #cond_>
  <ng-container *ngTemplateOutlet="addTest_"></ng-container>
  <div class="flex flex-row gap-2 items-start">
    <ng-container *ngTemplateOutlet="tabs_"></ng-container>
  </div>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.label' | translate }}</mat-label>
    <input [formControl]="parent.controls.label" matInput />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.description' | translate }}</mat-label>
    <textarea
      rows="3"
      [formControl]="parent.controls.description"
      matInput
      [cdkTextareaAutosize]="true"
    ></textarea>
  </mat-form-field>
  <div class="flex flex-row gap-x-2 items-center">
    <mat-checkbox [formControl]="parent.controls.autoReflex"
      >{{ 'hlds.settings.algorithms.autoReflex' | translate }}
    </mat-checkbox>
    <mat-icon [matTooltip]="'hlds.node-edit.type.cond.autoReflex-heading' | translate">{{
      'shared.icon.info' | translate
    }}</mat-icon>
  </div>
  @if (!(parent.controls.cFalse.value || parent.controls.cTrue.value)) {
    <div class="text-error-500">
      {{ 'hlds.node-edit.type.cond.true-false-help' | translate }}
    </div>
  }
  <ng-container *ngTemplateOutlet="condThenElse_"> </ng-container>
</ng-template>

<ng-template #addTest_>
  <mat-accordion class="mb-4" multi>
    <mat-expansion-panel #addTest>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.test.table.add-tooltip' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      @if (addTest.expanded) {
        <div class="flex flex-col">
          <hlds-test-add
            [varNamesToExclude]="nes.usedUnused.all"
            (varNamesAdded)="nes.varNamesAdded($event, true)"
          ></hlds-test-add>
        </div>
      }
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.node-edit.type.' + parent.controls.type.value + '.tests-accordion' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="chips_"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
<ng-template #chips_>
  <div class="flex flex-col">
    @if (nes.usedUnused.used.length) {
      <div class="flex flex-row gap-x-2 items-center">
        <div
          [innerHTML]="
            'hlds.node-edit.type.' + parent.controls.type.value + '.tests-used' | translate
          "
        ></div>
        <mat-icon
          [matTooltip]="
            'hlds.node-edit.type.' + parent.controls.type.value + '.tests-used-tooltip' | translate
          "
          >{{ 'shared.icon.info' | translate }}</mat-icon
        >
      </div>
      <mat-chip-set class="mb-4">
        @for (varName of nes.usedUnused.used; track varName) {
          <mat-chip
            removable
            [matTooltip]="varName + ' ' + units(varName)"
            (click)="nes.clipBoardCond([varName])"
            (removed)="remove(varName)"
            >{{ varName }}
            <button matChipRemove>
              <mat-icon>{{ 'shared.icon.cancel' | translate }}</mat-icon>
            </button>
          </mat-chip>
        }
      </mat-chip-set>
    }
    <ng-container *ngTemplateOutlet="chipsUnused_"></ng-container>
  </div>
</ng-template>
<ng-template #chipsUnused_>
  @if (nes.usedUnused.unused.length) {
    <div class="flex flex-row gap-x-2 items-center">
      <div
        [innerHTML]="
          'hlds.node-edit.type.' + parent.controls.type.value + '.tests-other' | translate
        "
      ></div>
      <mat-icon
        [matTooltip]="
          'hlds.node-edit.type.' + parent.controls.type.value + '.tests-other-tooltip' | translate
        "
        >{{ 'shared.icon.info' | translate }}</mat-icon
      >
    </div>
    <mat-chip-set class="mb-4">
      @for (varName of nes.usedUnused.unused; track varName) {
        <mat-chip
          removable
          (click)="nes.clipBoardCond([varName])"
          [matTooltip]="varName + ' ' + units(varName)"
          (removed)="nes.varNamesAdded([varName], true)"
          >{{ varName }}
          <button matChipRemove>
            <mat-icon>{{ 'shared.icon.add' | translate }}</mat-icon>
          </button>
        </mat-chip>
      }
    </mat-chip-set>
  }
</ng-template>

<ng-template #condAdvanced_>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.cond' | translate }}</mat-label>
    <textarea rows="3" #inputCond [formControl]="nes.controls.cond" matInput></textarea>
    @if (nes.controls.cond.value && nes.controls.cond.getError('error')) {
      <mat-error>
        {{ nes.controls.cond.getError('error') }}
      </mat-error>
    }
    @if (!nes.controls.cond.value) {
      <mat-hint>{{ 'hlds.node-edit.type.cond.hint.cond' | translate }}</mat-hint>
    }
  </mat-form-field>
</ng-template>

<ng-template #condThenElse_>
  <div class="grid grid-cols-2 gap-x-2">
    @for (tf of parent.falseTrue; track tf) {
      <div class="flex flex-col">
        <div class="flex flex-row justify-start items-center gap-x-2">
          <mat-checkbox [formControl]="tf.control.enable">{{
            tf.label.enable | translate
          }}</mat-checkbox>
          <mat-icon [matTooltip]="'hlds.node-edit.type.cond.true-false-help' | translate">{{
            'shared.icon.info' | translate
          }}</mat-icon>
        </div>
        @if (tf.control.enable.value) {
          <mat-form-field>
            <mat-label>{{ tf.label.edge | translate }}</mat-label>
            <input [formControl]="tf.control.edge" matInput required />
          </mat-form-field>
          @if (tf.descendents.length) {
            <mat-form-field>
              <mat-label>{{ tf.label.link | translate }}</mat-label>
              <mat-select panelClass="select-800" [formControl]="tf.control.link">
                <mat-select-trigger>{{
                  selectTrigger(tf.descendents, tf.control.link.value)
                }}</mat-select-trigger>
                <mat-option class="smaller-font is-flex nowrap" disabled>
                  <div class="grid grid-cols-[20%_80%] gap-2">
                    <div>{{ 'hlds.node-edit.type.cond.link.select.type' | translate }}</div>
                    <div>{{ 'hlds.node-edit.type.cond.link.select.label' | translate }}</div>
                  </div>
                </mat-option>
                @for (descend of tf.descendents; track descend) {
                  <mat-option class="smaller-font is-flex nowrap" [value]="descend.id">
                    <div class="grid grid-cols-[20%_80%] gap-2">
                      <div class="truncate">
                        {{ 'hlds.node-edit.type.' + descend.type + '.radio' | translate }}
                      </div>
                      <div class="truncate">{{ descend.label }}</div>
                    </div>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #tabs_>
  <div class="flex-1 flex flex-col">
    <mat-tab-group
      mat-stretch-tabs="false"
      selectedIndex="{{ nes.condition.selectedIndex }}"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.node-edit.type.cond.tab.basic' | translate">
        <ng-container *ngTemplateOutlet="condBasic_"></ng-container>
      </mat-tab>
      <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.node-edit.type.cond.tab.advanced' | translate">
        <ng-container *ngTemplateOutlet="condAdvanced_"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #condBasic_>
  <div [formGroup]="nes.form">
    <ng-container formArrayName="eqs">
      @for (_eqForm of nes.eqs.controls; track _eqForm; let index = $index, last = $last) {
        <div class="flex flex-row gap-x-2 items-baseline" [formGroupName]="index">
          <mat-form-field class="w-[12rem]">
            <mat-label>{{ 'hlds.node-edit.type.cond.basic.type-label' | translate }}</mat-label>
            <mat-select panelClass="select-800" [formControlName]="nes.controlName('type')">
              @for (type of nes.nodeCondEqTypes; track type) {
                <mat-option class="smaller-font" [value]="type">{{
                  'hlds.node-edit.type.cond.basic.type.' + type | translate
                }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-[20rem]">
            <mat-label>{{ 'hlds.node-edit.type.cond.basic.varName' | translate }}</mat-label>
            <input [formControlName]="nes.controlName('varName')" matInput />
          </mat-form-field>
          <mat-form-field class="w-[7rem]">
            <mat-label>{{
              'hlds.node-edit.type.cond.basic.operator-compare' | translate
            }}</mat-label>
            <mat-select panelClass="select-800" [formControlName]="nes.controlName('operator')">
              @for (operator of nes.operators; track operator) {
                <mat-option class="smaller-font" [value]="operator">{{ operator }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-[10rem]">
            <mat-label>{{ 'hlds.node-edit.type.cond.basic.value' | translate }}</mat-label>
            <input [formControlName]="nes.controlName('value')" matInput />
          </mat-form-field>
          @if (!last) {
            <mat-form-field class="w-[8rem]">
              <mat-label>{{
                'hlds.node-edit.type.cond.basic.operator-logical' | translate
              }}</mat-label>
              <mat-select panelClass="select-800" [formControlName]="nes.controlName('logic')">
                @for (operator of nes.logicOperators; track operator) {
                  <mat-option class="smaller-font" [value]="operator">{{ operator }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <button
            mat-icon-button
            [matTooltip]="'hlds.node-edit.type.cond.basic.tooltip.add' | translate"
            (click)="nes.eqAdd()"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'hlds.node-edit.type.cond.basic.tooltip.delete' | translate"
            (click)="nes.eqDel(index)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      }
    </ng-container>
  </div>
</ng-template>
