import { KeyValue } from '@angular/common';
import { TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

export type MenuItemType =
  | 'array'
  | 'arrayLength'
  | 'boolean'
  | 'chip'
  | 'date'
  | 'dateOnly'
  | 'id'
  | 'link'
  | 'template'
  | 'titleCase';

export const menuItemRecord: Record<MenuItemType, MenuItemType> = {
  array: 'array',
  arrayLength: 'arrayLength',
  boolean: 'boolean',
  chip: 'chip',
  date: 'date',
  dateOnly: 'dateOnly',
  id: 'id',
  link: 'link',
  template: 'template',
  titleCase: 'titleCase',
};

export type MenuItemChipType = ThemePalette | 'error' | 'success';
/**
 * A generic item, that can be used in menus, tables, etc. It can be also extended'
 */
export interface MenuItem_<T> {
  align: 'center' | 'right';
  count: number;
  color: 'link';
  copyId: boolean;
  chipMap: Record<string, MenuItemChipType>;
  disabled: boolean;
  disabledText: string;
  formControl: FormControl;
  hidden: boolean;
  /** If idSecondary = true then ignore the column when search for an id column */
  idSecondary: boolean;
  items: KeyValueMenuItem<T>[] | Record<string, KeyValueMenuItem<T>>;
  label: string;
  noTranslate: boolean;
  route: string;
  template: () => TemplateRef<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  tooltip: string;
  type: MenuItemType;
  unsortable: boolean;
}

export type MenuItem<T> = Partial<MenuItem_<T>>; // Use MenuItem vs MenuItem_
export type KeyValueMenuItem<T = string> = KeyValue<T, MenuItem<T>>;
