import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import * as fde from 'fast-deep-equal';
import { filter, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private readonly router = inject(Router);

  /**
   * Compare two objects and return true if they are equal to each other or both are null
   *
   * @param a object or null
   * @param b object or null
   */
  deepEqual<T = object>(a: T | null, b: T | null): boolean {
    if (a && b) {
      return fde(a, b);
    } else {
      return !a && !b; // if both are falsy, return true
    }
  }

  /**
   * Convert kebab case to space
   *
   * @param value a-b-c or a_b_c
   * @return a b c
   */
  kebabToSpace(value: string): string {
    return typeof value === 'string' ? value.replace(/[-_]/g, ' ') : value;
  }

  /**
   * Return NavigationEnd events.
   *
   * @param destroyRef DestroyRef
   * @return NavigationEnd events
   */
  navigation(destroyRef: DestroyRef): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      takeUntilDestroyed(destroyRef),
    );
  }
  /**
   * @param obj Object
   * @return true if obj isn't an object or the object has no keys
   */
  objectIsEmpty(obj: object | null | undefined): boolean {
    return obj?.constructor === Object && Object.keys(obj).length === 0;
  }

  /**
   * Parse url into UrlSegment[]
   *
   * @param url Optional, if not set router.url (current path) is used
   * @return UrlSegment[]
   */
  parseUrl(url?: string): UrlSegment[] {
    const { router } = this;

    if (url === undefined) {
      url = router.url;
    } else {
      url = typeof url === 'string' ? url.trim() : '';
    }

    return router.parseUrl(url).root?.children[PRIMARY_OUTLET]?.segments || [];
  }
}
