<div class="flex flex-col h-full">
  <hlds-header (showMenu)="drawer.toggle()" [ngClass]="{ hidden: iframe }"> </hlds-header>
  <mat-drawer-container class="flex-1">
    <mat-drawer #drawer mode="over" class="p-4 w" (openedStart)="openStart()">
      <hlds-home [drawer]="true" (routed)="drawer.close()"></hlds-home>
    </mat-drawer>
    <mat-drawer-content class="h-full overflow-hidden">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
