import { TestModel } from '@ci';
import { KeyValueMenuItem } from '../../shared/menu-item';

export const testItems: () => KeyValueMenuItem<keyof TestModel>[] = () => [
  {
    key: 'varName',
    value: { label: 'hlds.test.table.col.varName' },
  },
  {
    key: 'description',
    value: { label: 'hlds.test.table.col.description' },
  },
  {
    key: 'unit',
    value: { label: 'hlds.test.table.col.unit' },
  },
  {
    key: 'unitType',
    value: { label: 'hlds.test.table.col.unitType' },
  },
  {
    key: 'category',
    value: { label: 'hlds.test.table.col.category' },
  },
];
