import { IToken } from '@chevrotain/types';
import { CstNode, CstParser } from 'chevrotain';
import { condRec as cr } from './cond-tokens';

export class CondParser extends CstParser {
  private readonly identifier = this.RULE(cr.identifier.name.toUpperCase(), () => {
    this.CONSUME(cr.identifier);
    this.CONSUME(cr.compareOperator);
    this.CONSUME(cr.value);
    this.OPTION(() => {
      this.SUBRULE(this.logicOperator);
    });
  });
  private readonly logicOperator = this.RULE(cr.logicOperator.name.toUpperCase(), () => {
    this.CONSUME(cr.logicOperator);
    this.OR([
      { ALT: () => this.SUBRULE(this.parenLeft) },
      { ALT: () => this.SUBRULE(this.identifier) },
    ]);
  });
  private readonly parenLeft = this.RULE(cr.parenLeft.name.toUpperCase(), () => {
    this.CONSUME(cr.parenLeft);
    this.MANY(() => {
      this.OR([
        { ALT: () => this.SUBRULE(this.parenLeft) },
        { ALT: () => this.SUBRULE(this.identifier) },
      ]);
    });
    this.CONSUME(cr.parenRight);
    this.OPTION(() => this.SUBRULE(this.logicOperator));
  });
  private readonly root = this.RULE('root', () => {
    this.OR([
      { ALT: () => this.SUBRULE(this.parenLeft) },
      { ALT: () => this.SUBRULE(this.identifier) },
    ]);
  });

  constructor() {
    super(cr);
    this.performSelfAnalysis();
  }

  /**
   * Calling this route starts a new parse
   *
   * @param input lexer.tokenize(text)
   * @return the output from "root"
   */
  go(input: IToken[]): CstNode {
    this.input = input;
    return this.root();
  }
}
