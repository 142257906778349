import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  condLogicOperator,
  condLogicOperators,
  condOperators,
  condParse,
  condRec,
  NodeAny,
  NodeBasic,
  nodeCondEqTypes,
  TreeClass,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { TreeService } from '../../services/tree/tree.service';
import { NodeEditComponent } from '../node-edit/node-edit.component';
import { CondEq } from './node-edit-cond.model';

type ErrorType = 'compare' | 'value' | 'varNotFound';

@Injectable()
export class NodeEditCondService {
  readonly condition = {
    selectedIndex: 0,
  };
  controls: Record<'cond', FormControl<string>> = {
    cond: new FormControl<string>('', { nonNullable: true }),
  };
  readonly nodeCondEqTypes = nodeCondEqTypes;
  errorType!: ErrorType | '';
  readonly errors: Record<ErrorType, string> = {
    compare: "Expecting token of type --> compareOperator <-- but found --> '' <--",
    value: "Expecting token of type --> value <-- but found --> '' <--",
    varNotFound: 'hlds.node-edit.type.cond.error.tests-condition-not-found',
  };
  form!: FormGroup;
  readonly logicOperators = condLogicOperators;
  readonly operators = condOperators;
  readonly usedUnused: Record<'all' | 'unused' | 'used', string[]> = {
    all: [],
    unused: [],
    used: [],
  };
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(FormBuilder);
  private readonly snackBar = inject(SnackBarService);
  private readonly translate = inject(TranslateService);
  private readonly treeService = inject(TreeService);
  private _parent!: NodeEditComponent;

  get parent(): NodeEditComponent {
    return this._parent;
  }

  set parent(parent: NodeEditComponent) {
    this._parent = parent;
    this.#init();
  }

  get eqs(): FormArray<FormGroup> {
    return this.form.get('eqs') as FormArray<FormGroup>;
  }

  clipBoardCond(varNames: string[]) {
    const {
      controls: { cond },
    } = this;
    const value = cond.value.trim();
    const vName = varNames.slice(-1)[0];

    this.#clipboard([vName]);

    if (value.length === 0) {
      cond.setValue(vName);
    } else {
      const andOr = ['and', 'or', '&&', '||', '('];
      const match = andOr.some((op) => value.toLowerCase().endsWith(op));
      if (match) {
        cond.setValue(value + ' ' + vName);
      }
    }
  }

  /**
   * Typesafe formControlName used in the template
   *
   * @param name keyof CondEdit
   * @return name
   */
  controlName(name: keyof CondEq): string {
    return name;
  }

  descendents(nodes: NodeAny[], node: NodeAny): NodeBasic[] {
    const result: NodeBasic[] = TreeClass.treeDescendentsSafe(nodes, node).map((n) =>
      TreeClass.nodeBasic(n),
    );

    result.sort((a, b) => a.type.localeCompare(b.type) || a.label.localeCompare(b.label));

    const match = result.findIndex((result) => result.id === node.id);

    // Don't include node in the result
    if (match >= 0) {
      result.splice(match, 1);
    }

    return result;
  }

  /**
   * Add a row to eqs
   *
   * @return the controls for the row added
   */
  eqAdd(): Record<keyof CondEq, FormControl> {
    const { eqs, fb } = this;
    const eq: CondEq = {
      logic: 'OR',
      operator: '',
      varName: '',
      value: '',
      type: 'simple',
    };

    eqs.push(fb.nonNullable.group(eq), { emitEvent: false });
    return this.eqControls(eqs.length - 1);
  }

  eqControls(index: number): Record<keyof CondEq, FormControl> {
    return this.eqs.at(index).controls as Record<keyof CondEq, FormControl>;
  }

  eqDel(index: number) {
    const { eqs } = this;
    eqs.removeAt(index);

    if (eqs.length === 0) {
      this.eqAdd();
    }
  }

  setUsedUnused(used?: string[]) {
    const {
      usedUnused,
      parent: {
        data: { testRows },
      },
    } = this;

    if (used) {
      usedUnused.used = used;
    }

    usedUnused.unused = testRows
      .filter((r) => !usedUnused.used.includes(r.varName))
      .map((r) => r.varName);
    usedUnused.all = [...usedUnused.used, ...usedUnused.unused];
  }

  varNamesAdded(vNames: string[], fromAdd = false): string[] {
    const {
      parent: {
        allTests,
        data: { testRows },
      },
      usedUnused,
    } = this;
    const result: string[] = [];

    for (const id of vNames.map((id) => id.toLowerCase())) {
      const test = allTests.find((v) => v.varName.toLowerCase() === id);

      if (test) {
        const { varName } = test;

        result.push(varName);

        if (!testRows.find((t) => t.varName == varName)) {
          testRows.push(test);
        }

        if (!usedUnused.used.includes(varName)) {
          usedUnused.used.push(varName);
        }
      }
    }

    this.setUsedUnused();

    if (fromAdd) {
      this.clipBoardCond(vNames);
    }

    return result;
  }

  #clipboard(varNames: string[]) {
    const { translate, snackBar, condition, eqs } = this;

    if (condition.selectedIndex === 0) {
      for (let index = 0; index < eqs.length; index++) {
        const controls = this.eqControls(index);
        if (!controls.varName.value.trim()) {
          controls.varName.setValue(varNames[0]);
          break;
        }
      }
    }

    if (!varNames.every((name) => !name)) {
      snackBar.open({
        message: translate.instant('shared.clipboard.copied'),
        messages: varNames,
        type: 'success',
      });

      setTimeout(() => navigator.clipboard.writeText(varNames.join(', ')));
    }
  }

  #conditionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {
        condition,
        translate,
        treeService,
        errors,
        usedUnused,
        parent: {
          formValue: { type },
        },
      } = this;

      this.errorType = '';

      if (type !== 'cond' || condition.selectedIndex === 0) {
        return null;
      }

      const error = treeService.conditionValidator(usedUnused.all, control.value);
      let message = '';

      if (error) {
        message = error.text;

        if (message === errors.value) {
          message = translate.instant('hlds.node-edit.type.cond.error.type.value');
        } else if (message === errors.varNotFound && error.ids?.length) {
          const newVarNames = this.varNamesAdded(error.ids);

          if (newVarNames.length === error.ids.length) {
            return null;
          }
        }

        if (!error.noTranslate) {
          message = translate.instant(message, { ids: error.ids });
        }
      }

      return message ? { error: message } : null;
    };
  }

  #init() {
    const {
      controls: { cond },
      destroyRef,
      fb,
    } = this;

    this.errorType = '';
    this.form = fb.nonNullable.group({ eqs: fb.array([]) });

    cond.setValue('');
    cond.setValidators(this.#conditionValidator());
    cond.valueChanges
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((val) => this.#setBasic(val.trim()));
    this.form.valueChanges
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => this.#setAdvanced());
  }

  #setAdvanced() {
    const {
      controls: { cond },
      eqs,
    } = this;
    const fields: string[] = [];

    for (let index = 0; index < eqs.length; index++) {
      const { varName, operator, value, logic } = this.eqControls(index);

      if (varName.value.trim() && operator.value.trim() && value.value.trim()) {
        fields.push('(' + varName.value.trim(), operator.value.trim(), value.value.trim() + ')');
        if (index < eqs.length - 1) {
          if (logic.value.trim()) {
            fields.push(logic.value);
          } else {
            break; // if not on the last row and there isn't AND/OR then invalid-ish
          }
        }
      }
    }

    cond.setValue(fields.join(' '), { emitEvent: false });
  }

  #setBasic(cond: string) {
    const { eqs } = this;
    const noEvent = { emitEvent: false };

    eqs.clear(noEvent); // clear and set one row
    this.eqAdd();

    if (cond) {
      const parse = condParse(cond, { strict: false });
      let eq = this.eqControls(0);

      for (const token of parse.tokens) {
        const {
          image,
          tokenType: { name, CATEGORIES },
        } = token;
        if (name === condRec.identifier.name) {
          eq.varName.setValue(image, noEvent);
        } else if (CATEGORIES?.length) {
          const category = CATEGORIES[0];
          if (category.name === condRec.compareOperator.name) {
            eq.operator.setValue(image, noEvent);
          } else if (category.name === condRec.value.name) {
            eq.value.setValue(image, noEvent);
          } else if (category.name === condRec.logicOperator.name) {
            eq.logic.setValue(condLogicOperator(image), noEvent);
            eq = this.eqAdd();
          }
        }
      }
    }
  }
}
