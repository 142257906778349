import { BaseModel } from './base.model';
import { EngineRecord } from './engine.model';
import { InterpModel } from './shared.model';
import { TreeRootModel } from './tree.model';

export type AlgorithmDraftState = 'draft' | 'published';
export type AlgorithmActiveState = 'active' | 'archive';
export const algorithmActiveStates: AlgorithmActiveState[] = ['active', 'archive'];

export interface AlgorithmModelNameDescription {
  description: string;
  name: string;
}

export type AlgorithmModelView = AlgorithmModelNameDescription;

export interface AlgorithmModel extends AlgorithmModelNameDescription, BaseModel {
  activeState: AlgorithmActiveState;
  author: string;
  description: string;
  draftState: AlgorithmDraftState;
  interps: InterpModel[];
  sims: EngineRecord[];
  tests: string[];
  tree: TreeRootModel;
}
