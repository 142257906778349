<div class="h-full flex flex-col">
  <mat-toolbar style="min-height: unset; height: 54px">
    <mat-toolbar-row class="justify-center">
      <img alt="HemoHub" src="./assets/img/hemohub.svg" style="width: 200px; height: 40px" />
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-accordion>
    <mat-expansion-panel #panel expanded>
      <mat-expansion-panel-header>Patient Orders</mat-expansion-panel-header>
      <hlds-orders (iframeId)="panel.close(); iframe($event)" class="p-2"></hlds-orders>
    </mat-expansion-panel>
  </mat-accordion>
  @if (sanitizeUrl) {
    <iframe [src]="sanitizeUrl" class="w-full flex-1" style="border: 4px solid #0064d1"> </iframe>
  }
</div>
