<hlds-route-title [title]="'hlds.settings.title' | translate"></hlds-route-title>

<div class="flex flex-col flex-1">
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.settings.algorithms.title' | translate">
      <hlds-settings-algorithms></hlds-settings-algorithms>
    </mat-tab>
    <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.settings.i18n.title' | translate">
      <hlds-settings-i18n></hlds-settings-i18n>
    </mat-tab>
  </mat-tab-group>
</div>
